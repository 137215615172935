import React, { useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Card from '../Components/Card/Card';
import SearchInput from '../Components/SearchInput';



const TabsSection = () => {
    const [searchInput, setSearchInput] = useState('');
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <section className="lg-mb-5 md-mb-3  lg-mt-3 md-mt-3 cardSection">
            <TabContext value={value}>
                <div className=" justify-content-around EventsList1" id="EventsList2">
                    <div className="home-page-tab" style={{ display: 'flex', flexGrow: 1, marginBottom: '1px', }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ justifyContent: 'space-around' }}>
                            <Tab value="3" sx={{ fontSize: '36px', color: '#797979' }} className="tab_class" label="UPCOMING" />
                            <Tab value="1" sx={{ fontSize: '36px', color: '#797979' }} className="tab_class" label="OPEN" />
                            <Tab value="4" sx={{ fontSize: '36px', color: '#797979' }} className="tab_class" label="CLOSED" />
                            <Tab value="2" sx={{ fontSize: '36px', color: '#797979' }} className="tab_class" label="RESULTS" />
                        </TabList>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} isHomePage />
                    </div>
                </div>
                <div >
                    <TabPanel value="1">
                        <Card searchInput={searchInput} isHomePage="true" eventMode="open" />
                    </TabPanel>
                    <TabPanel value="2">
                        <Card searchInput={searchInput} isHomePage="true" eventMode="result" />
                    </TabPanel>
                    <TabPanel value="3">
                        <Card searchInput={searchInput} isHomePage="true" eventMode="upcoming" />
                    </TabPanel>
                    <TabPanel className="tabs" value="4">
                        <Card searchInput={searchInput} isHomePage="true" eventMode="closed" />
                    </TabPanel>
                </div>
            </TabContext>
        </section>
    )
}

export default TabsSection