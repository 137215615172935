import React, { useState, useEffect } from "react";
import "./style.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";

const PaymentPage = (props) => {

  const [stripePromise, SetStripePromise] = useState(null);
  const [clientSecret, setclientSecret] = useState("");

  useEffect(() => {
    fetch("/config").then(async (e) => {
      const publishedkeys = `pk_live_j1AIaPpzJBcB4n54BsI4tykc00iCSLbhya`;

      SetStripePromise(loadStripe(`${publishedkeys}`));
      setclientSecret(`${props.client}`);
    });
  }, []);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: `${clientSecret}`,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <div className="col-md-8 offset-md-2 offset-1 col-10">
      <div className="creditCard">Payment method</div>
      <hr className="mt-0" />

      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <div className="stripePage">
            <CheckoutForm from={props.from} />
          </div>
        </Elements>
      )}
    </div>
  );
};

export default PaymentPage;
