import { serviceConsumer } from './ServiceConsumer';

const url = process.env.REACT_APP_BASE_URL;
export async function addCompletetion(payload, id) {
  const response = await serviceConsumer('POST', `${url}/feis/${id}/competition`, payload);
  return response;
}

export async function getFeisConfiguration(id) {
  const response = await serviceConsumer('GET', `${url}/feis/${id}/competition`);
  return response;
}

export async function getPageNumber(id) {
  const response = await serviceConsumer('GET', `${url}/feis/getPage/${id}`);
  return response;
}

export async function getPageGradeNumber(id) {
  const response = await serviceConsumer('GET', `${url}/grade-exam/getPage/${id}`);
  return response;
}

export async function editCompletion(payload, id) {
  const response = await serviceConsumer('PUT', `${url}/feis/${id}/competition/${payload._id}`, payload);
  return response;
}
