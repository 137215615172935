import { useNavigate } from "react-router-dom";
import React from "react";
import feisLogo from "../Assets/feis-logo.svg";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./Footer.css";

export const Footer = (props) => {
  const navigate = useNavigate();

  return ( 
    <div className="d-flex"style={{background:"#424242"}}>
      <div className="background "></div>
      <div className="container footerContainer ">
        <div className="footer-top">
          <div>
            <a href="/">
              <img style={{ cursor: "pointer" }} src={feisLogo} Nav href="/" t="logo" />
            </a >
          </div>
          <div className="d-flex flex-grow-1 justify-content-end">

            <div >
              <button className="login-ft-btn" onClick={() => navigate("/login")}>
                Log in
              </button>
            </div>
            <div>
              <button className="signup-btn " onClick={() => navigate("/signup")}>
                Sign up
              </button>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-end">
          <div className=" col footer-bottom d-flex justify-content-end align-items-center">
            <div className="link d-flex align-items-center">
              <a id="mailIcon" href="mailto:info@feis.link" style={{ color: "white !important" }}>
                info@feis.link
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/feis.link" target="_blank">
                <FacebookRoundedIcon style={{ color: 'white',fontSize:"22px" }} />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/feis.link/" target="_blank">
                <InstagramIcon style={{ color: 'white',fontSize:"22px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Footer;
