// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpImageDiv {
  position: fixed;
  bottom: 5%;
  right: 1.5%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Help/Help.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,WAAW;AACb","sourcesContent":[".helpImageDiv {\n  position: fixed;\n  bottom: 5%;\n  right: 1.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
