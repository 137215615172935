import React from 'react'
import Back from '../Back/Back';

const SingleLineTitle = (props) => {
    return (
        <>
            <div className="single-line-title">
                <Back onBackClick={props.onBackClick} from="single" />
                <div className="org-title-name" style={{ marginTop: "1px" }}>{props.titleName}</div>
            </div>
            <div className="hr-org-title" />
        </>
    )
}

export default SingleLineTitle