import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} PopperProps={{ className }}>
        {props.children}
    </Tooltip>
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#303030',
        padding: '8px',
        borderRadius: '4px',
        border: "1px solid #fafafa",
        opacity: 0.3,
        boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.05);"

    },
    '& .MuiTooltip-arrow': {
        color: "#f8f9fa",
        fontSize: "14px",
        zIndex: 99,
    },
});
