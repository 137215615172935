import { serviceConsumer } from "../network/ServiceConsumer";

export const getIsTeacher = async (setLoading) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user`;
    setLoading(true);
    try {
        const response = await serviceConsumer("GET", url)
        localStorage.setItem("isTeacher", response?.teacher);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
}