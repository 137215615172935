import React from 'react'
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { v4 as uuidv4 } from 'uuid';

const CollaboratorsList = ({ collaborators, event }) => {
    let collabList = event === "feis" ? [collaborators?.adjudicators, collaborators?.musicians, collaborators?.sponsors] : [collaborators?.examiners, collaborators?.sponsors];
    return (
        <>
            {collabList?.map((item, index) => {
                if (item?.length > 0) {
                    return (
                        <div key={uuidv4()}>
                            {index === 0 && <div className="pageHeaders">{event === "feis" ? "Adjudicators" : "Examiners"}</div>}
                            {index === 1 && <div className="pageHeaders">{event === "feis" ? "Musicians" : "Sponsors"}</div>}
                            {index === 2 && <div className="pageHeaders">Sponsors</div>}

                            <hr className="mt-0" />
                            <div className="Adjudicators" style={{ flexWrap: "wrap" }}>
                                {item?.map((eachItem, index) => (
                                    <div className="adjudicator" key={uuidv4()}>
                                        <Box
                                            className="adjudicatorCard"
                                            sx={{ boxShadow: 1 }}
                                            style={{
                                                width: "100%",
                                                minHeight: "81px",
                                                background: "#FFFFFF",
                                                borderRadius: "4px",
                                                boxShadow: "0px 15px 28px rgba(0, 0, 0, 0.05)",
                                                padding: "5px 10px",
                                            }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <Avatar
                                                    src={eachItem.photo}
                                                    style={{height: "65.14px",width: "65.14px",margin: "5px",}}
                                                    alt="logo"
                                                />
                                                <div className="adjudicatorDescription">
                                                    <h6>{eachItem.name}</h6>
                                                    <p style={{ color: "#68AD3F" }}>{eachItem.location}</p>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                }
            })}
        </>
    )
}

export default CollaboratorsList