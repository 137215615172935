import React, { useState, useEffect } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";

import { LOGOUT } from "../../redux/actionTypes/authActionType";
import { passwordValidation, repeatPwdValidation } from "../../helper/Validations";
import Button from "../../Components/Button/Button"
import { serviceConsumer } from "../../network/ServiceConsumer";
import FormikControl from "../../Components/formik/FormikControl";
import "./Style.css";


const Password = () => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState("");
  const [showError, setShowError] = useState("");

  const passwordInitialValues = {
    password: "",
    repeatPassword: "",
  };

  const passwordValidationSchema = Yup.object().shape({
    password: passwordValidation,
    repeatPassword: repeatPwdValidation
  });

  const passwordUpdateHandler = async (values) => {
    let payload = {
      password: values.password,
    };
    const url = `${process.env.REACT_APP_BASE_URL}/renew-pw`
    try {
      const response = await serviceConsumer("POST", url, payload)
      if (response.success) {
        localStorage.clear();
        setShowSuccess("Password reset successfully");
        setTimeout(() => {
          dispatch({
            type: LOGOUT,
          });
        }, 7000);
        window.location.replace("/");
      }
    } catch (err) {
      setShowError(err.messages);
      setTimeout(() => setShowError(""), 3000);
    }
  };
  useEffect(() => {
    document.title = "Feis.Link - My account"
  })
  return (
    <TabPanel value="2">
      <Formik
        initialValues={passwordInitialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={passwordUpdateHandler}
      >
        {(formik) => (
          <Form className="account-form" autoComplete="off">
            <h4 className="title-signin grey-42">Password</h4>
            <p className="grey-42">
              Change your password when you need it. For security, we recommend
              that it be made up of letters and numbers.
            </p>
            <hr className="mt-0 " />
            {showSuccess && <Alert sx={{ color: "#68AD3F" }} icon={false} severity="success">{showSuccess}</Alert>}
            {showError && <Alert style={{ color: "error" }} icon={false} severity="error">{showError}</Alert>}
            <FormikControl
              className="accountInput"
              control="input"
              type="password"
              label="New Password:"
              name="password"
              placeHolder="New Password"
            />
            <FormikControl
              className="accountInput"
              control="input"
              type="password"
              label="Repeat New Password:"
              name="repeatPassword"
              placeHolder="Repeat New Password"
            />
            <br />
            <Button className={formik.isValid && formik.dirty ? " filled" : " disabled"} type="submit">Change password</Button>
          </Form>
        )}
      </Formik>
    </TabPanel>
  );
};

export default Password;
