// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-nav{
    font-weight: 500;
    font-size: 16px;
    color: #68AD3F;
    padding-left:5px;
    }
    
@media only screen and (min-width: 0px) and (max-width: 480px) {
    .back-nav{
        font-size: 14px;
        }
}

@media only screen and (min-width: 481px) and (max-width: 967px) {
    .back-nav{
        font-size: 14px;
        }        
}`, "",{"version":3,"sources":["webpack://./src/Components/Back/Back.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB;;AAEJ;IACI;QACI,eAAe;QACf;AACR;;AAEA;IACI;QACI,eAAe;QACf;AACR","sourcesContent":[".back-nav{\n    font-weight: 500;\n    font-size: 16px;\n    color: #68AD3F;\n    padding-left:5px;\n    }\n    \n@media only screen and (min-width: 0px) and (max-width: 480px) {\n    .back-nav{\n        font-size: 14px;\n        }\n}\n\n@media only screen and (min-width: 481px) and (max-width: 967px) {\n    .back-nav{\n        font-size: 14px;\n        }        \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
