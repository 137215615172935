import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchInput = ({ searchInput, setSearchInput, isHomePage }) => {
    return (
        <div
            className="d-flex justify-content-between align-items-center searchDiv"
            style={{ border: '1px solid #797979', borderRadius: '5px', color: '#797979', marginTop: '5px', }}
        >
            <input
                type="text"
                name="search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder={isHomePage ? "Search competition..." : "Search..."}
                className={isHomePage ? "search" : "searchc"}
            />
            <SearchOutlinedIcon style={{ margin: '0px 5px' }} />
        </div>
    )
}

export default SearchInput