import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderOrg from '../../../Headers/HeaderOrg';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { sortCompetitions } from '../../../../helper/SortSyllabus';

const PublishedResults = () => {
    const [event, setEvent] = useState()
    const [levels, setLevels] = useState([])
    const [competitions, setCompetitions] = useState([])
    const [selectedLevel, setSelectedLevel] = useState("")
    const [selectedCompetitionId, setSelectedCompetitionId] = useState("")
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const [noLevels, setNoLevels] = useState(false)

    useEffect(() => {
        getInitialDetails();
    }, []);


    const getInitialDetails = async () => {
        try {
            setInitialLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)

            const levelUrl = `${process.env.REACT_APP_BASE_URL}/feis/levels/published`
            const levelPayload = {
                id: eventId,
            }
            const levelResponse = await serviceConsumer("POST", levelUrl, levelPayload)
            if (levelResponse.result.length > 0) {
                setLevels(levelResponse.result)
                setSelectedLevel(levelResponse.result[0])
            } else {
                setNoLevels(true)
            }

            const competitionsUrl = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`
            const competitionsPayload = {
                feis_id: eventId,
                level: levelResponse.result[0]
            }
            const competitionsResponse = await serviceConsumer("POST", competitionsUrl, competitionsPayload)
            const competitions = competitionsResponse.result;
            const sortedCompetitions = sortCompetitions(competitions)
            setCompetitions(sortedCompetitions)
            setInitialLoading(false)
        } catch (error) {
            navigate("/pageNotFound", { replace: true })
        }
    };

    // get competitions api
    const getCompetitions = async (level) => {
        try {
            setLoading(true)
            const payload = {
                feis_id: eventId,
                level: level
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`;
            const response = await serviceConsumer("POST", url, payload)
            const competitionsResponse = response.result;
            const sortedCompetitions = sortCompetitions(competitionsResponse)
            setCompetitions(sortedCompetitions)
            setSelectedCompetitionId("")
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    //level select handler
    const selectLevelHandler = async (levelSelected) => {
        setSelectedLevel(levelSelected)
        await getCompetitions(levelSelected)
    }

    //competition select handler
    const selectCompetitionHandler = async (event) => {
        setSelectedCompetitionId(event.target.value)
    }

    const nextButtonHandler = () => {
        navigate(`/complete-results/${event?.name}?id=${event?._id}&compId=${selectedCompetitionId}`)
    }

    return (
        <>
            {loading && <Loader />}
            {initialLoading && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={initialLoading} />
            <div className="org-body-white">
                {!initialLoading && !noLevels && <>
                    <div className="org-body-title pt-4">Results</div>
                    <div className="mt-1 hr-org-body"></div>
                    <div className="d-md-flex mt-4 gap-md-4">
                        <div>
                            <div className="search-label">Select a level:</div>
                            <select name="level" className="check-in-select" onChange={(e) => selectLevelHandler(e.target.value)}>
                                {levels?.map(levelOption => <option key={uuidv4()} selected={levelOption === selectedLevel} value={levelOption} >{levelOption}</option>)}
                            </select>
                        </div>
                        <div className='mt-4 mt-md-0'>
                            <div className="search-label">Select a competition:</div>
                            <select name="competition" className="check-in-select" onChange={selectCompetitionHandler}>
                                <option defaultValue hidden>Select</option>
                                {(competitions?.filter(item => item?.results_published)).map(competitionOption => <option key={uuidv4()} selected={competitionOption._id === selectedCompetitionId} value={competitionOption._id} >{competitionOption.code}-{competitionOption.group}</option>)}
                            </select>
                        </div>
                    </div >
                    <div className="d-flex align-items-center mt-5"> <button className={selectedCompetitionId ? "filled" : "disabled"} onClick={nextButtonHandler} >Next</button></div>
                </>
                }
                {!initialLoading && noLevels && <div className="d-flex justify-content-center mt-5">There are no published results</div>}

            </div >
            <Help />

        </>
    )
}

export default PublishedResults