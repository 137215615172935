import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Loader from '../../Components/Loader/Loader';
import './Auth.css';
import Button from '../../Components/Button/Button';
import { serviceConsumer } from '../../network/ServiceConsumer';
import feisLogo from '../../Assets/feis-logo.svg';
import Help from '../../Components/Help/Help';
import { emailValidation } from '../../helper/Validations';

const ForgotPasswordOrg = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const headerClickHandler = () => {
    navigate('/');
  };
  const redirectToLoginHandler = () => {
    navigate('/login-org');
  };

  //Initial values for formik
  const forgotPasswordInitialValues = {
    email: '',
  };

  //Validation Schema for Formik
  const forgotPasswordValidationSchema = Yup.object().shape({
    email:emailValidation,
  });

  const forgotPasswordHandler = async (values) => {
    setLoading(true);
    try {
      let value = { ...values, email: values.email.toLowerCase() };
      const url = `${process.env.REACT_APP_BASE_URL}/forgot-pw/org`;
      const response = await serviceConsumer('POST', url, value);
      if (response.success) {
        setError('');
        setSuccess('An email has been sent with the instructions to reset your password');
      }
    } catch (error) {
      setError(error.messages);
      setSuccess('');
    }
    setLoading(false);
  };

  const changeHandler = (e, name, setFieldValue) => {
    setFieldValue(name, e.target.value);
    setError('');
  };

  return (
    <div className="d-flex flex-column justify-content-between p-4 auth-org" style={{ minHeight: '100vh' }}>
      {loading && <Loader />}
      <div>
        <img src={feisLogo} alt="feis-logo" onClick={headerClickHandler} style={{ cursor: 'pointer' }} />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {success && (<Alert className="recover-alert" sx={{ color: '#68AD3F' }} icon={false} severity="success" >{success}</Alert>)}
        {error && (<Alert className="recover-alert" sx={{ color: 'error', flexGrow: 1, justifyContent: 'center' }} icon={false} severity="error">{error}</Alert>)}
        <p className="auth-title-org auth-title-org-mob">Recover your password</p>
        <p className="recover-pwd-msg">Enter the email address associated with your account</p>
        <Formik
          initialValues={forgotPasswordInitialValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={forgotPasswordHandler}
        >
          {({ setFieldValue, dirty, isValid, }) => {
            return (
              <Form autoComplete="off">
                <div>
                  <div className="d-flex flex-column">
                    <label className="label-head">Email:</label>
                    <Field
                      className="input-box"
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={(e) => { changeHandler(e, 'email', setFieldValue); }}
                    />
                    <ErrorMessage className="auth-error" name="email" component="div" />
                  </div>
                  <div className="d-flex flex-grow-1 justify-content-center mt-3">
                    <Button className={isValid && dirty ? 'filled' : 'disabled'}>Next</Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <Button className="text-btn mt-3" onClick={redirectToLoginHandler}>Log in</Button>
      </div>
      <Help />
    </div>
  );
};

export default ForgotPasswordOrg;
