import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import HeaderOrg from '../../../Headers/HeaderOrg';
import '../../RedirectFeis/FeisCompetitor.css';
import Loader from '../../../../Components/Loader/Loader';
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle';
import sort from '../../../../Assets/managefeisGrade/sort-org.svg';
import "../../../../Components/Table.css"
import { sortGrades } from '../../../../helper/SortSyllabus';
import { downloadCandidatesCSV, downloadCsv, jsonToCSV } from '../../../../helper/DownloadHandlers';

const CandidateLists = () => {
  const location = useLocation();
  console.log(location)
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');
  const [responseArray, setResponseArray] = useState([]);
  const [tableArr, setTableArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sorterData, setSorterData] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchInput, setSearchInput] = useState('');
  const [err, setErr] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: '',
    id: '',
  });
  const initialValues = {
    selectedOption: '',
  };
  const [gradeName, setGradeName] = useState([]);

  useEffect(() => {
    //grade and candidate
    getAllDetails();
  }, [selectedOption]);

  const getAllDetails = async () => {
    try {
      setLoading(true);
      setTableArr([]);
      setErr('');
      const candidateUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/registration/candidates`;
      const payload = {
        grade_id: !selectedOption.id && eventId,
        gradeExamFeeId: selectedOption.value && selectedOption?.id,
        type: selectedOption.id ? 'one' : 'all',
      };
      const candidateResponse = await serviceConsumer('POST', candidateUrl, payload);
      setResponseArray(candidateResponse?.result);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${eventId}`;

      const response = await serviceConsumer('GET', url);
      setGradeName(sortGrades(response.grade_exam_fees));
      setLoading(false);
    } catch (error) {
      setErr(error.messages);
      setLoading(false);
    }
  }

  //sort competitor as per column
  const sortCompetitorsHandler = (sorter) => {
    let order = sorter === sorterData ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';

    order === 'asc' && sorter === 'id' && tableArr.sort((a, b) => a.card_number - b.card_number);
    order === 'asc' && sorter === 'index' && tableArr.sort((a, b) => a.id - b.id);
    order === 'desc' && sorter === 'index' && tableArr.sort((a, b) => b.id - a.id);
    order === 'asc' && sorter === 'competitor' && tableArr.sort((a, b) => `${a.dancerData.lname}`.localeCompare(`${b.dancerData.lname}`));
    order === 'asc' && sorter === 'dob' && tableArr.sort((a, b) => a.dancerData.birthday - b.dancerData.birthday);
    order === 'asc' && sorter === 'competitions' && tableArr.sort((a, b) => a.competitonData[0].code.localeCompare(b.competitonData[0].code));
    order === 'desc' && sorter === 'id' && tableArr.sort((a, b) => b.card_number - a.card_number);
    order === 'desc' && sorter === 'competitor' && tableArr.sort((a, b) => `${b.dancerData.lname}`.localeCompare(`${a.dancerData.lname}`));
    order === 'desc' && sorter === 'dob' && tableArr.sort((a, b) => b.dancerData.birthday - a.dancerData.birthday);
    order === 'desc' && sorter === 'competitions' && tableArr.sort((a, b) => b.competitonData[0].code.localeCompare(a.competitonData[0].code));
    setTableArr(tableArr);
    setSorterData(sorter);
    setSortOrder(order);
  };


  // search input in competitors
  useEffect(() => {
    const tableArr = responseArray?.filter((searching) => {
      if (searchInput === '') {
        return searching;
      } else if (
        searching?.dancerData?.fname?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        searching?.dancerData?.lname?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        searching?.dancerData?.school?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        searching?.dancerData?.birthday?.includes(searchInput?.toLowerCase()) ||
        searching?.gradeExamFeesData
          ?.map((item) => item.grade_name)
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      ) {
        return searching;
      }
    });
    const updatedTableArr = tableArr.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setTableArr(updatedTableArr);
  }, [responseArray, searchInput]);

  function extractReadableString(url) {
    const decodedUrl = decodeURIComponent(url);
    const readableString = decodedUrl?.substring(decodedUrl?.lastIndexOf('/') + 1);
    return readableString;
  }
  const exportToCSVHandler = () => {
    const csvContent = jsonToCSV(tableArr);
    const url = location?.pathname;
    const readableString = extractReadableString(url);
    console.log(readableString);
    downloadCandidatesCSV(csvContent, readableString);
  }

  return (
    <>
      {loading && <Loader />}
      <HeaderOrg />
      <SingleLineTitle titleName="Candidate List " />
      <div className="org-body-white">
        <div className="d-flex gap-2 align-items-center mt-3">
          <div className="org-body-title">Candidates</div>
          <div className="competitor-count ">{tableArr.length}</div>
        </div>
        <div className="mb-3 hr-org-body" />
        <div
          className="d-md-inline-flex d-sm-grid align-items-center justify-content-between"
          style={{ gap: '29px' }}
        >
          <div className="search-label">
            Search:
            <div>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search..."
                className="searchFeisComp searchFeisComps"
              />
            </div>
          </div>
          <div className="search-label">Grade:
            <div className="">
              <Formik initialValues={initialValues}>
                <Form>
                  <Field
                    as="select"
                    name="selectedOption"
                    value={selectedOption.value}
                    className="searchFeisCompsSelect"
                    onChange={(e) => {
                      setSelectedOption({
                        id: gradeName.find((item) => item.grade_name === e.target.value)?._id,
                        value: e.target.value,
                        label: e.target.value,
                      });
                    }}
                  >
                    <option value="all Grades">All Grades</option>
                    {gradeName.map((grade) => (
                      <option key={grade._id} value={(e) => e.target.value}>{grade.grade_name}</option>
                    ))}
                  </Field>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', overflowX: 'scroll', marginTop: '30px', marginBottom: '50px', }}>
          <table className="table my_table competitorTable ">
            <tbody>
              <tr className="header-row">
                <td className="text-center mx-5 grey-4 p-25-5 w-10">
                  # &nbsp;
                  <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('index')} />
                </td>
                <td className="mx-5 grey-4 p-25-5 w-35" >Candidate &nbsp;
                  <span>
                    <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('competitor')} />
                  </span>
                </td>
                <td className="grey-4 p-25-5 w-35">
                  Grades &nbsp;
                  <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('dob')} />
                </td>
                <td className="grey-4 p-25-5 w-25 text-center">
                  DOB &nbsp;
                  <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler('dob')} />
                </td>
              </tr>
              {tableArr.map((dancer, index) => {
                return (
                  <tr style={{ background: '#FFFFFF', fontSize: '12px', height: '31px', }}>
                    <td className="text-center mx-5 py-4 w-10 grey-30">{dancer.id}</td>
                    <td className="mx-5 w-35 grey-30">
                      <div>{dancer?.dancerData?.fname}&nbsp;{dancer?.dancerData?.lname}<div style={{ opacity: '0.3' }}>{dancer?.dancerData?.school}</div></div>
                    </td>

                    <td className="w-10 grey-30">
                      {dancer.gradeExamFeesData.map((gradeName, index) => index === dancer.gradeExamFeesData.length - 1
                        ? `${gradeName.grade_name}`
                        : `${gradeName.grade_name}, `
                      )}
                    </td>
                    <td className="text-center w-25 grey-30">{dancer?.dancerData?.birthday}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {tableArr.length > 0 && <div className="d-flex justify-content-end">
            <button className="outlined" onClick={exportToCSVHandler}>Export to CSV</button>
          </div>}
          {!loading && tableArr.length === 0 && searchInput !== '' && err.trim() === '' && responseArray.length > 0 && (
            <div className="d-flex justify-content-center my-5">There are no Candidate matching your search</div>
          )}
          {!loading && responseArray.length === 0 && (
            <div className="d-flex justify-content-center my-5">There are no Candidate registered for this event</div>
          )}
          {!loading && err && responseArray.length !== 0 && (
            <div className="d-flex justify-content-center error-class">{err}</div>
          )}
        </div>
      </div>
      <Help />
    </>
  );
};

export default CandidateLists;
