import React, { useState, useEffect } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import { addYears } from 'date-fns';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import FormikControl from '../../Components/formik/FormikControl';
import { useDispatch } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { serviceConsumer } from '../../network/ServiceConsumer';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { regionData } from '../../helper/DataArrays';
import { accessTillEventDetails, accessTillVenueDetails } from '../../helper/CreateDispatches';
import { feisCapData, gradeCapData, lavelCap } from '../../helper/OrgDataArrays';
import { createEventDetailValidationSchema } from '../../helper/Validations';
import { formatDate } from '../../helper/formatDate';

const EventDetailsFies = (props) => {
  const [showGlobalCap, SetShowGlobalcap] = useState(false);
  const [levelCap, SetlevelCap] = useState(false);
  const [ShowGradeNumber, setShowGradeNumber] = useState(false);
  const [eventDetails, setEventDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [updateApi, setupdateApi] = useState(false);
  const [isLive, setIsLive] = useState(false)
  const maxDate = addYears(new Date(), 100);
  let EventID = localStorage.getItem('ID');
  const TAB = localStorage.getItem('TAB');
  const page = localStorage.getItem('page');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search)
  const previousEvent = searchParams.get('previousEvent')

  //  to get eventDetails on back
  const getEventDetails = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url = props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer('GET', url);

        setEventDetails(props.type === "feis" ? response.Feis : response.grade_exams);
        setIsLive(response?.Feis?.live)
        if (!previousEvent) {
          localStorage.setItem('page', props.type === "feis" ? response.Feis?.page : response.grade_exams?.page);
        }
        if (response.success == true) {
          setupdateApi(true);
          dispatchFunction(false, dispatch);
          if (response?.Feis?.cap?.capType == 'Level Cap') {
            SetlevelCap(true);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const EventDetailsInitialValues = props.type === "feis"
    ? generateEventDetailsInitialValues(eventDetails, ['feis_date', 'opening_date', 'closing_date'])
    : generateEventDetailsInitialValues(eventDetails, ['exam_date', 'opening_date', 'closing_date']);

  // validation
  const eventDetailsValidationSchema = props.type === "feis"
    ? createEventDetailValidationSchema('feis_date', 'Feis date must be greater than the closing date.')
    : createEventDetailValidationSchema('exam_date', 'Exam date must be greater than the closing date.');

  useEffect(() => {
    getEventDetails();
    page > TAB && dispatchFunction(false, dispatch);
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur(); // Remove focus from the input field
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      e.currentTarget.blur(); // Remove focus from the input field
    }
  };

  // submitHandler
  const EventDetalsHandler = async (values, formik) => {
    if (updateApi && !previousEvent) {
      try {
        checkClosingIsBeforeAfterDate(eventDetails, values.closing_date, formik);
        setLoading(true);
        let value = {
          ...values,
          opening_date: moment(values.opening_date).format("yyyy-MM-DD"),
          closing_date: moment(values.closing_date).format("yyyy-MM-DD"),
          ...(props.type === "feis" ? { feis_date: moment(values.feis_date).format("yyyy-MM-DD") } : { exam_date: moment(values.exam_date).format("yyyy-MM-DD") }),
          ...(props.type === "feis" ? { eventDate: moment(values.feis_date).format("yyyy-MM-DD") } : { eventDate: moment(values.exam_date).format("yyyy-MM-DD") }),

        };

        // const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`;
        const url = props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer('PUT', url, value);

        if (response.success === true) {
          props.setEventName(values.name)
          setTimeout(() => { props.setValue('2'); }, 300);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        checkClosingIsBeforeAfterDate(eventDetails, values.closing_date, formik)
        setLoading(true);
        let prev = { ...eventDetails }
        if (previousEvent) {
          prev = { ...eventDetails, feis_id: eventDetails._id };
          delete prev['_id'];
        }
        let dateModifiedValues = {
          ...values,
          opening_date: moment(values.opening_date).format("yyyy-MM-DD"),
          closing_date: moment(values.closing_date).format("yyyy-MM-DD"),
          ...(props.type === "feis" ? { feis_date: moment(values.feis_date).format("yyyy-MM-DD") } : { exam_date: moment(values.exam_date).format("yyyy-MM-DD") }),
          ...(props.type === "feis" ? { eventDate: moment(values.feis_date).format("yyyy-MM-DD") } : { eventDate: moment(values.exam_date).format("yyyy-MM-DD") }),
        };

        let value = { ...(previousEvent ? { ...prev, previous: true } : {}), ...dateModifiedValues, page: 1, live: false };
        //const url = `${process.env.REACT_APP_BASE_URL}/feis`;
        const url = props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam`;
        const response = await serviceConsumer('POST', url, value);
        if (response.success === true) {
          props.setEventName(values.name)
          localStorage.setItem('ID', props.type === "feis" ? response.feis._id : response.grade_exam._id);
          localStorage.setItem('TAB', 2);
          if (Number(localStorage.getItem('page')) < 1) {
            localStorage.setItem('page', 1);
          }
          setTimeout(() => {
            props.setValue('2');
            if (previousEvent) {
              props.type === "feis" ? navigate('/organizer-create-feisanna') : navigate('/organizer-create-grade')
              localStorage.setItem('previousEvent', true);
            }
            dispatchFunction(true, dispatch);
            setLoading(false);
          }, 300);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={EventDetailsInitialValues}
          validationSchema={eventDetailsValidationSchema}
          onSubmit={EventDetalsHandler}
          validateOnChange
          validateOnBlur
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <>
                <div className="detailsHeaders">
                  <h4 className="eventPage">Event Details</h4>
                  <p className="eventDescription">Complete these details to create event.</p>
                </div>
                <div className="eventSection">
                  <div className="Aboutevent">
                    <h4 className="eventPage mt-md-5 ">About event</h4>
                    <div className="d-flex flex-column ">
                      <div className={formik.errors.name && formik.touched.name ? '' : 'input-control'}>
                        <label className={formik.errors.name && formik.touched.name ? 'label-head-org-error' : 'label-head-org'}>
                          {props.type === "feis" ? "Name of Feis:" : "Name of Exam:"}
                        </label>
                        <br />
                        <Field
                          className={formik.errors.name && formik.touched.name ? 'input-box-org-error' : 'input-box-org'}
                          type="text"
                          name="name"
                          defaultValue={eventDetails?.name}
                          placeholder="Name"
                        />
                        <ErrorMessage className={formik.errors.name && formik.touched.name ? 'auth-error' : ''} name="name" component="div" />
                      </div>
                      <div className={formik.errors.contact_email && formik.touched.contact_email ? '' : 'input-control'}>
                        <label
                          htmlFor="contact_email"
                          className={formik.errors.contact_email && formik.touched.contact_email ? 'label-head-org-error' : 'label-head-org'}>
                          {props.type === "feis" ? "Feis Contact Email:" : "Exam Contact Email"}
                        </label>
                        <br />
                        <Field
                          className={formik.errors.contact_email && formik.touched.contact_email ? 'input-box-org-error' : 'input-box-org'}
                          onBlur={() => { formik.setFieldTouched('contact_email', true); }}
                          type="email"
                          name="contact_email"
                          placeholder="Email"
                        />
                        <ErrorMessage className="auth-error" name="contact_email" component="div" />
                      </div>
                      <div className={formik.errors.region && formik.touched.region ? '' : 'input-control'}>
                        <label className={formik.errors.region && formik.touched.region ? 'label-select-org-error' : 'org-select'}>
                          Region:
                        </label>
                        <FormikControl
                          className={(isLive && !previousEvent) ? "input-box-org-select-disabled" : formik.errors.region && formik.touched.region ? 'input-box-select-error ' : 'input-box-org-select'}
                          control="select"
                          name="region"
                          showAs="Select"
                          options={regionData}
                          id="schoolSelect"
                          setFieldValue={formik.setFieldValue}
                          style={{ fontSize: '12px !important' }}
                          disabled={isLive && !previousEvent}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Eventdate">
                    <h4 className="eventPage mt-5">Event date</h4>
                    <div className="d-flex flex-column ">
                      <div className={formik.errors.opening_date && formik.touched.opening_date ? '' : 'input-control'}>
                        <label className={formik.errors.opening_date && formik.touched.opening_date ? 'label-head-org-error' : 'label-head-org'}>
                          Reg. Open Date:
                        </label>
                        <br />
                        <Field name="opening_date">
                          {({ field, form }) => (
                            <DatePicker
                              className={form.errors.opening_date && form.touched.opening_date ? 'input-box-org-date-error' : 'input-box-org-date'}
                              selected={field.value}
                              onChange={(date) => {
                                form.setFieldValue('opening_date', date || '');
                                if (form.values.feis_date) {
                                  formik.setFieldTouched(props.type === "feis" ? 'feis_date' : "exam_date", true);
                                  formik.validateField(props.type === "feis" ? 'feis_date' : "exam_date");
                                }
                                if (form.values.closing_date) {
                                  formik.setFieldTouched('closing_date', true);
                                  formik.validateField('closing_date');
                                }
                                setTimeout(() => { formik.validateForm(); }, 0);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched('opening_date', true);
                                setTimeout(() => { formik.validateForm(); }, 0);
                              }}
                              minDate={new Date()}
                              maxDate={maxDate}
                              placeholderText="MM/DD/YYYY"
                            />
                          )}
                        </Field>
                        <ErrorMessage className="auth-error" name="opening_date" component="div" />
                      </div>
                      <div className={formik.errors.closing_date && formik.touched.closing_date ? '' : 'input-control'}>
                        <label className={formik.touched.closing_date && formik.errors.closing_date ? 'label-head-org-error' : 'label-head-org'}>
                          Reg. Close Date:
                        </label>
                        <br />
                        <Field name="closing_date">
                          {({ field, form }) => (
                            <DatePicker
                              className={formik.errors.closing_date && formik.touched.closing_date ? 'input-box-org-date-error' : 'input-box-org-date'}
                              selected={field.value}
                              onChange={(date) => {
                                form.setFieldValue('closing_date', date || '');
                                form.setFieldTouched('closing_date');
                                if (form.values[props.type === "feis" ? 'feis_date' : 'exam_date']) {
                                  formik.setFieldTouched(props.type === "feis" ? 'feis_date' : "exam_date", true);
                                  formik.validateField(props.type === "feis" ? 'feis_date' : "exam_date");
                                }
                                setTimeout(() => { formik.validateForm(); }, 0);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched('closing_date', true);
                                setTimeout(() => { formik.validateForm(); }, 0);
                              }}
                              placeholderText="MM/DD/YYYY"
                              minDate={new Date()}
                              maxDate={maxDate}
                            />
                          )}
                        </Field>
                        <ErrorMessage className="auth-error" name="closing_date" component="div" />
                      </div>
                      <div className={formik.errors[props.type === "feis" ? 'feis_date' : 'exam_date'] && formik.touched[props.type === "feis" ? 'feis_date' : 'exam_date'] ? '' : 'input-control'}>
                        <label className={formik.errors[props.type === "feis" ? 'feis_date' : 'exam_date'] && formik.touched[props.type === "feis" ? 'feis_date' : 'exam_date'] ? 'label-head-org-error' : 'label-head-org'}>
                          {props.type === "feis" ? 'Feis Date' : 'Exam Date'}
                        </label>
                        <br />
                        <Field name={props.type === "feis" ? 'feis_date' : 'exam_date'}>
                          {({ field, form }) => (
                            <DatePicker
                              className={form.errors[props.type === "feis" ? 'feis_date' : 'exam_date'] && form.touched[props.type === "feis" ? 'feis_date' : 'exam_date'] ? 'input-box-org-date-error' : 'input-box-org-date'}
                              selected={field.value}
                              onChange={(date) => {
                                formik.setFieldTouched(props.type === "feis" ? 'feis_date' : 'exam_date', true);
                                form.setFieldValue(props.type === "feis" ? 'feis_date' : 'exam_date', date || '');
                                formik.validateField(props.type === "feis" ? 'feis_date' : 'exam_date');
                                if (form.values.closing_date) {
                                  formik.setFieldTouched('closing_date', true);
                                  formik.validateField('closing_date');
                                }
                                setTimeout(() => { formik.validateForm(); }, 0);
                              }}
                              onBlur={() => formik.setFieldTouched(props.type === "feis" ? 'feis_date' : 'exam_date', true)}
                              minDate={new Date()}
                              maxDate={maxDate}
                              placeholderText="MM/DD/YYYY"
                            />
                          )}
                        </Field>
                        <ErrorMessage className="auth-error" name={props.type === "feis" ? "feis_date" : "exam_date"} component="div" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="EventDateOrg">Options</h3>
                </div>
                <div className={formik.errors.cap?.capType && formik.touched.cap?.capType ? '' : 'input-control'}>
                  <label
                    style={{ fontSize: '12px' }}
                    className={formik.errors.cap?.capType && formik.touched.cap?.capType ? 'label-select-org-error' : 'org-select'}
                  >
                    Cap:
                  </label>
                  <FormikControl
                    className={formik.errors.cap?.capType && formik.touched.cap?.capType ? 'input-box-select-error ' : 'input-box-org-select'}
                    control="select"
                    name="cap.capType"
                    onWheel={handleWheel}
                    onKeyDown={handleKeyDown}
                    showAs="Select"
                    options={props.type === "feis" ? feisCapData : gradeCapData}
                    id="schoolSelect"
                    setFieldValue={formik.setFieldValue}
                    onChange={(e) => {
                      formik.setFieldTouched('cap.capType');
                      formik.setFieldValue('cap.value', '');
                      formik.setFieldValue('cap.grades', '');
                      formik.setFieldValue('cap.champ', '');
                      formik.setFieldTouched('cap.value', false);
                      formik.setFieldTouched('cap.grades', false);
                      formik.setFieldTouched('cap.champ', false);
                      formik.setFieldValue('cap.capType', e.target.value);
                      changeCapHandler(e.target.value, setShowGradeNumber, SetShowGlobalcap, SetlevelCap);
                    }}
                    onClick={() => {
                      formik.validateField('cap.capType')
                      formik.validateForm()
                    }}
                  />
                </div>
                {levelCap && (
                  <div className="levelCap-Values">
                    <div className={!formik.values.cap?.grades && !formik.values.cap?.champ && formik.touched.cap?.grades
                      ? ''
                      : 'input-control'}>
                      <label
                        className={!formik.values.cap?.grades && !formik.values.cap?.champ && formik.touched.cap?.grades
                          ? 'label-select-org-error'
                          : 'org-select'}
                      >
                        {lavelCap[0].key}
                        <div className="subKey">{lavelCap[0].subKey}</div>
                      </label>
                      <Field
                        className={!formik.values.cap?.grades && !formik.values.cap?.champ && formik.touched.cap?.grades
                          ? 'input-box-org-error'
                          : 'input-box-org'
                        }
                        autoComplete="off"
                        type="number"
                        name="cap.grades"
                        placeholder="Enter Number"
                        step="any"
                        onWheel={handleWheel}
                        onKeyPress={(e) => {
                          if (e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div
                      className={!formik.values.cap?.champ && !formik.values.cap?.grades && formik.touched.cap?.champ
                        ? ''
                        : 'input-control'
                      }
                    >
                      <label
                        className={!formik.values.cap?.champ && !formik.values.cap?.grades && formik.touched.cap?.champ
                          ? 'label-select-org-error'
                          : 'org-select'
                        }
                      >
                        {lavelCap[1].key}
                        <div className="subKey">{lavelCap[1].subKey}</div>
                      </label>
                      <Field
                        className={!formik.values.cap?.champ && !formik.values.cap?.grades && formik.touched.cap?.champ
                          ? 'input-box-org-error'
                          : 'input-box-org'
                        }
                        autoComplete="off"
                        type="number"
                        name="cap.champ"
                        placeholder="Enter Number"
                        step="any"
                        onWheel={handleWheel}
                        onKeyPress={(e) => {
                          if (e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

                {formik.values.cap?.capType === 'Global Cap' && (
                  <>
                    <Field
                      className={formik.errors.cap?.value && formik.touched.cap?.value
                        ? 'input-box-org-error mt-2'
                        : 'input-box-org mt-2'
                      }
                      autoComplete="off"
                      type="number"
                      name="cap.value"
                      placeholder="Enter Number"
                      step="any"
                      onWheel={handleWheel}
                      onKeyPress={(e) => {
                        if (e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                    />
                    {formik.errors.cap?.value && formik.touched.cap?.value && (
                      <div className="error">{formik.errors.cap?.value}</div>
                    )}
                    <div />
                    <br />
                  </>
                )}
                {ShowGradeNumber && (
                  <div className={!formik.errors.value ? 'input-control' : ''}>
                    <Field
                      className={
                        formik.errors.cap?.value && formik.touched.cap?.value
                          ? 'input-box-org-error mt-2'
                          : 'input-box-org mt-2'
                      }
                      autoComplete="off"
                      type="number"
                      name="cap.value"
                      placeholder="Enter  Number "
                      step="any"
                      onWheel={handleWheel}
                      onKeyPress={(e) => { if (e.key === 'e') { e.preventDefault(); } }}
                    />
                    {formik.errors.cap?.value && formik.touched.cap?.value && (<div className="error">{formik.errors.cap?.value}</div>)}
                    <br />
                  </div>
                )}

                <div className="mt-3 mb-5" >
                  <button
                    className={getDisabled(formik, props.type).isValid && formik.dirty ? 'filled' : 'disabled'}
                    type="submit"
                    disabled={getDisabled(formik, props.type).disabled}
                  >
                    Next
                  </button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      )}
      <ToastContainer />
    </div>
  );
};

export default EventDetailsFies;

const getDisabled = (formik, type) => {
  const isValid = !!(
    formik.values.name &&
    (formik.values.region && formik.values.region != 'Select') &&
    formik.values.contact_email &&
    !formik.errors.contact_email &&
    formik.values.opening_date &&
    formik.values.closing_date &&
    formik.values.closing_date >= formik.values.opening_date &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) >= formik.values.closing_date &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) &&
    (type === "feis" ? formik.values.feis_date : formik.values.exam_date) &&
    (formik.values.cap?.capType && formik.values.cap?.capType != 'Select') &&
    (formik.values.cap?.capType == 'Global Cap'
      ? formik.values.cap?.value
      : formik.values.cap?.capType == 'Level Cap'
        ? formik.values.cap?.grades || formik.values.cap?.champ
        : true)
  );

  const disabled = !isValid && !formik.dirty && formik.isSubmitting;
  return { disabled, isValid };
};

const changeCapHandler = (capValue, setShowGradeNumber, SetShowGlobalcap, SetlevelCap) => {
  if (capValue === 'No Cap') {
    setShowGradeNumber(false);
    SetShowGlobalcap(false);
    SetlevelCap(false);
  } else if (capValue === 'Global Cap') {
    SetShowGlobalcap(true);
    SetlevelCap(false);
    setShowGradeNumber(false);
  } else if (capValue === 'Level Cap') {
    SetlevelCap(true);
    SetShowGlobalcap(false);
    setShowGradeNumber(false);
  }
};

const generateEventDetailsInitialValues = (eventDetails, dateFields) => {
  const initialValues = {
    name: eventDetails?.name || '',
    contact_email: eventDetails?.contact_email || '',
    region: eventDetails?.region || '',
    cap: {
      capType: eventDetails?.cap?.capType || '',
      GlobalCap: eventDetails?.cap?.GlobalCap || '',
      value: eventDetails?.cap?.value || '',
      grades: eventDetails?.cap?.grades || '',
      champ: eventDetails?.cap?.champ || '',
    },
    percentageToAward: eventDetails?.percentageToAward || '',
    minimumNumberToAward: eventDetails?.minimumNumberToAward || '',
  };

  dateFields.forEach((field) => {
    initialValues[field] = eventDetails?.[field] && formatDate(eventDetails?.[field]);
  });

  return initialValues;
};


const dispatchFunction = (next, dispatch) => {
  next ? accessTillEventDetails(dispatch) : accessTillVenueDetails(dispatch);
};

const convertTZ = (date) => {
  const newDate = moment(date).format('YYYY/MM/DD');
  return newDate ? new Date(newDate) : date;
}

const checkClosingIsBeforeAfterDate = (eventDetails, closingDate, formik) => {
  if (eventDetails?.fees && eventDetails?.fees[0]?.lateFee?.afterDate) {
    toast.dismiss()
    const afterDate = new Date(eventDetails.fees[0]?.lateFee?.afterDate);
    if (afterDate && new Date(afterDate) < convertTZ(closingDate)) {
      return true;
    } else {
      toast.error('Late fee date must be less than the updated closing date. To Continue, please make the changes in Fees Tab');
      return false
    }
  } else return true;
}