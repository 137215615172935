export const backHandler = (ID, navigate, eventName, type) => {
    const redirectFeis = localStorage.getItem("redirectFeis");
    const manage = localStorage.getItem("Manage");

    if (localStorage.getItem("ID")) {
        if (redirectFeis === "goLive") {
            localStorage.removeItem("redirectFeis")
            const path = type === "feis" ? "/organiser-dasboard/createevent-feisanna/finalfiesDetails" : "/organiser-dasboard/createevent-grade/finalGradeDetails"
            navigate(path)
        }
        else if (redirectFeis === "edit") {
            localStorage.removeItem("redirectFeis")
            // navigate(`/event-details/${eventName}?id=${ID}`);
            navigate(`/event-details?id=${ID}`);
        } else if (redirectFeis === "create") {
            localStorage.removeItem("redirectFeis")
            navigate("/organizer-dashboard");
        } else if (manage) {
            const path = type === "feis" ? `/organizer-manage-feis?id=${ID}&Manage=feis` : `/organizer-manage-grade?id=${ID}&Manage=grade`;
            navigate(path);
        } else {
            navigate("/organizer-dashboard");
        }
    } else {
        navigate("/organizer-dashboard");
    }
};