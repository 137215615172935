import React from 'react'
import { v4 as uuidv4 } from 'uuid';

import Checkbox from '../../../../Components/Checkbox/Checkbox'
import "./ResultTable.css"
import "../../../../Components/Table.css"

const NineJudgeResult = ({ results, keyProp }) => {
    const refinedResults = results.map(dancer => {
        const refinedScores = [[], [], []]
        dancer?.scores?.forEach((item, index) => {
            refinedScores[item?.scores_by_round[0]?.round - 1].push({ judge: item?.judge_details?.name, score: item?.scores_by_round[0]?.score, irish_points: item.irish_points });
        });
        return {
            cardNumber: dancer?.card_number?.card_number,
            fname: dancer?.dancer_fname,
            lname: dancer?.dancer_lname,
            school: dancer?.school,
            overallPosition: dancer?.overallPosition,
            overallScore: dancer?.irish_points,
            scores: refinedScores
        }
    })
    let roundCount = [1, 2, 3]
    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }} key={keyProp}>
            <table className="table my_table competitorTable">
                <tbody>
                    <tr className="header-row">
                        <td className="text-center p-25-5 w-3">Award Received</td>
                        <td className="text-center p-25-5 w-2">#</td>
                        <td className="text-center w-3">Final Place</td>
                        <td className="text-center w-4">Total Irish Points</td>
                        <td className="mx-5 p-25-5 w-9">Competitor</td>
                        {roundCount.map((item) => {
                            return <td key={uuidv4()} className="text-center grey-42 p-25-5 w-9">{`Round ${item}`}</td>
                        })}
                    </tr>
                    <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                        <td className="text-center grey-42 p-25-5 w-3"></td>
                        <td className="text-center grey-42 p-25-5 w-2"></td>
                        <td className="text-center w-3"></td>
                        <td className="text-center w-4"></td>
                        <td className="mx-5 grey-42 p-25-5 w-9"></td>
                        {refinedResults[0]?.scores?.map((item, index) => {
                            return <td key={uuidv4()} className="text-center grey-42 p-25-5 w-3">
                                <div className="d-flex  gap-1 px-2">
                                    <div className="nineJudgeHead col-3 align-items-center">{item[0] ? item[0]?.judge : "-"}</div>
                                    <div className="nineJudgeHead col-3 align-items-center">{item[1] ? item[1]?.judge : "-"}</div>
                                    <div className="nineJudgeHead col-3 align-items-center">{item[2] ? item[2]?.judge : "-"}</div>
                                    <div className="nineJudgeHead col-3 align-items-center"> Total Irish Points<br />{index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"} Round </div>
                                </div>
                            </td>
                        })}
                    </tr>
                    {refinedResults?.map((item, index) => {
                        return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                            <td className="text-center mx-5 py-4 w-3 grey-30"><Checkbox /></td>
                            <td className="text-center mx-5 py-4 w-2 grey-30">{item?.cardNumber}</td>
                            <td className="text-center mx-5 py-4 w-3 grey-30">{item?.overallPosition}</td>
                            <td className="text-center mx-5 py-4 w-4 grey-30">{item?.overallScore}</td>
                            <td className="mx-5 w-9 grey-30">
                                <div>{item?.fname} &nbsp;{item?.lname}<div style={{ opacity: "0.3" }}>{item?.school}</div></div>
                            </td>

                            {refinedResults[index]?.scores?.map((item, index) => {
                                return <td key={uuidv4()} className="text-center grey-30 p-25-5 w-3">
                                    <div className="d-flex align-items-center gap-1 px-2">
                                        <div className='col-3'>{item[0] ? item[0]?.score : "-"}</div>
                                        <div className='col-3'>{item[1] ? item[1]?.score : "-"}</div>
                                        <div className='col-3'>{item[2] ? item[2]?.score : "-"}</div>
                                        <div className='col-3'>{(item[0]?.irish_points ? item[0]?.irish_points : 0) + (item[1]?.irish_points ? item[1]?.irish_points : 0) + (item[2]?.irish_points ? item[2]?.irish_points : 0)}</div>

                                    </div>
                                </td>
                            })}
                        </tr>
                    })}

                </tbody>
            </table>
        </div >
    )
}

export default NineJudgeResult