import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HeaderOrg from "../Headers/HeaderOrg";
import ManageTitle from "../../Components/TitleElements/ManageTitle";
import Help from "../../Components/Help/Help";
import { manageFeisArray } from "./ManageFeisArray";
import { manageGradeArray } from "./ManageGradeArray";
import { useDispatch, useSelector } from "react-redux";
import { SET_MODAL } from "../../redux/actionTypes/modalActionType";
import "./ManageFeisGrade.css";
import AddCompetitorModal from "../../Components/Modals/OrganizerModel/ManageFeis/Entries/AddCompetitorModal";
import AddedCompetitor from "../../Components/Modals/OrganizerModel/ManageFeis/Entries/AddedCompetitorModal";
import DirectUrlModal, {
  ScheduledPdf,
} from "../../Components/Modals/OrganizerModel/ManageFeis/Entries/DirectUrlModal";
import CollaboratorModal from "../../Components/Modals/OrganizerModel/ManageFeis/Events/CollaboratorModal";
import SelectOldCollaboratorModal from "../../Components/Modals/OrganizerModel/ManageFeis/Events/SelectOldCollaboratorModal";
import AddCollaboratorModal from "../../Components/Modals/OrganizerModel/ManageFeis/Events/AddCollaboratorModal";
import AccomodationModal from "../../Components/Modals/OrganizerModel/ManageFeis/Events/AccomodationModal";
import AccomodationModel from "../../Components/Modals/OrganizerModel/AccomodationModel";
import { serviceConsumer } from "../../network/ServiceConsumer";
import Loader from "../../Components/Loader/Loader";
import AddExaminerModel from "../../Components/Modals/OrganizerModel/AddExaminerModel";
import AddExaminerModal from "../../Components/Modals/OrganizerModel/ManageFeis/Events/AddExaminerModal";
import { v4 as uuidv4 } from "uuid";
import { downloadCSV } from "../../helper/DownloadHandlers";
import { formatDate } from "../../helper/formatDate";
import { handlePdfUpload } from "../../helper/handleUploads";
import { ShowToastMessage } from "../../helper/ShowToastMessage";
import AddStagesModal from "../../Components/Modals/OrganizerModel/ManageFeis/ScheduleMaker/AddStagesModal";

const ManageFeisGrade = () => {
  const [eventProp, setEventProp] = useState();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const sylInputRef = useRef();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("id");
  const ManageType = searchParams.get("Manage");
  const [sylErr, setsylErr] = useState(false);
  const [syllabus, setsyllabus] = useState("");
  const [syllabusUrl, setSyllabusUrl] = useState("");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [isStageCreated, setIsStageCreated] = useState(false);

  const url =
    ManageType === "feis"
      ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
      : `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}`;

  const getEventDetail = async () => {
    try {
      setLoading(true);
      const response = await serviceConsumer("GET", url);
      if (ManageType === "feis") {
        setsyllabus(response?.Feis?.schedulePdfUrl);
        setSyllabusUrl(response?.Feis?.schedulePdfUrl);
        setIsStageCreated(response?.Feis?.isStageCreated);
        localStorage.setItem("page", response.Feis?.page);
        setEventProp(response.Feis);
      } else {
        setEventProp(response.grade_exams);
        localStorage.setItem("page", response.grade_exams?.page);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventDetail();
  }, []);

  useEffect(() => {
    sylErr && ShowToastMessage("error", sylErr);
    setsylErr("");
  }, [sylErr]);

  const downloadCsvClickHandler = async () => {
    downloadCSV(ManageType, setLoading, eventId, eventProp.name);
  };

  const eventDateString =
    eventProp?.eventType === "Feiseanna"
      ? eventProp.feis_date
      : eventProp?.exam_date; //recieved as iso
  const eventDate = formatDate(eventDateString); //converted to date format
  const currDate = new Date(); //to compare todays date
  eventDate.setHours(0, 0, 0, 0);
  currDate.setHours(0, 0, 0, 0);

  const enableOthers = currDate <= eventDate; //enable others only till feis date
  const disableTabulate = currDate < eventDate; //disable tabulate until feis date

  // const enabledTabulate=true;
  let arr =
    eventProp?.eventType === "Feiseanna" ? manageFeisArray : manageGradeArray;
  const openModal = (modalName) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: modalName,
        eventId: eventProp?._id,
      },
    });
  };

  //on card click of feis/grade - manage page
  const cardClickHandler = (
    isModal,
    path,
    name,
    id,
    modalName,
    tab,
    downloadCsv,
    eventProp,
    from,
    upload,
    schedule
  ) => {
    if (isModal) {
      openModal(modalName);
    } else {
      if (path && name && id) {
        path === "/tabulate" && localStorage.setItem("resultStatus", "create");
        navigate(`${path}/${eventProp?.name}?id=${eventProp?._id}`, {
          state: { eventName: eventProp?.name },
        });
      }
      path &&
        !name &&
        id &&
        !tab &&
        !from &&
        navigate(`${path}?id=${eventProp?._id}`);
      path &&
        !name &&
        id &&
        !tab &&
        from &&
        navigate(`${path}?id=${eventProp?._id}&Manage=${ManageType}`);

      if (path && tab) {
        localStorage.setItem("TAB", tab);
        localStorage.setItem("ID", eventProp?._id);
        navigate(`${path}`);
      }
      downloadCsv && downloadCsvClickHandler();

      if (upload) {
        if (syllabus) openModal("ScheduledPdf");
        else sylInputRef.current.click();
      }
      if (schedule) {
        if (isStageCreated) navigate(`/manage-schedule?id=${eventProp?._id}`);
        else openModal("ScheduleMaker");
      }
    }
  };

  //This function to upload pdf receives the link and name as args from the response of handle Pdf upload
  //The same function is used to delete and args are passed from the ScheduledPdf modal as ""
  const addScheduleToEventDetail = async (sylLink, sylName, closeModal) => {
    try {
      setUploadLoader(true);
      const payload = {
        schedulePdfUrl: sylLink,
        schedulePdfName: sylName,
      };
      const response = await serviceConsumer("PUT", url, payload);
      sylName &&
        ShowToastMessage("success", `Schedule pdf uploaded successfully!`);
      !sylName &&
        ShowToastMessage("success", "Schedule pdf deleted successfully!");
      setSyllabusUrl(syllabusUrl);
      setsyllabus(sylName);
      closeModal && openModal("");
      setUploadLoader(false);
    } catch (error) {
      setUploadLoader(false);
    }
  };

  const handlesylSelect = (event) => {
    handlePdfUpload(
      event,
      setsyllabus,
      setSyllabusUrl,
      setsylErr,
      setUploadLoader,
      "",
      true,
      addScheduleToEventDetail
    );
  };

  const backClickHandler = () => {
    navigate("/organizer-dashboard");
  };
  return (
    <>
      {(loading || uploadLoader) && <Loader />}
      <HeaderOrg />
      <ManageTitle
        eventLogo={eventProp?.logo}
        eventName={eventProp?.name}
        eventType={eventProp?.eventType}
        eventDate={eventDateString}
        loading={loading}
        onBackClick={backClickHandler}
      />
      <div className="db-body">
        {!loading && (
          <>
            {arr.map((item) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <div className="org-body-title pt-4">{item.mainTitle}</div>
                  <div className="mt-1 hr-org-body" />
                  <div className="managecard-list mt-4">
                    {item.details.map((eachItem) => (
                      <div
                        key={uuidv4()}
                        className={
                          disableTabulate && eachItem?.title === "Tabulate"
                            ? "managecardDisabled"
                            : enableOthers
                            ? "managecard"
                            : eachItem.cardClickEnabled
                            ? "managecard"
                            : eachItem?.title === "Awards Table"
                            ? "managecard"
                            : "managecardDisabled"
                        }
                        style={{ columnGap: "10px" }}
                        onClick={() =>
                          cardClickHandler(
                            eachItem.isModal,
                            eachItem.path,
                            eachItem.name,
                            eachItem.id,
                            eachItem.modal,
                            eachItem.tab,
                            eachItem.downloadCsv,
                            eventProp,
                            eachItem.from,
                            eachItem.upload,
                            eachItem.schedule
                          )
                        }
                      >
                        <img
                          className="manage-img"
                          src={eachItem.logo}
                          alt=""
                        />
                        <div>
                          <div className="managecard-head">
                            {eachItem.title}
                          </div>
                          <div className="managecard-descr mt-1">
                            {eachItem.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              );
            })}
            <Help />
            <div style={{ display: "none" }}>
              <input type="file" ref={sylInputRef} onChange={handlesylSelect} />
            </div>
            <div className="error mt-2">{sylErr}</div>
            <ToastContainer />
          </>
        )}
      </div>
      {modal.modal === "AddCompetitor" && (
        <AddCompetitorModal modalState={true} />
      )}
      {modal.modal === "AddedCompetitor" && (
        <AddedCompetitor modalState={true} />
      )}
      {modal.modal === "DirectLink" && (
        <DirectUrlModal modalState={true} manageType={ManageType} />
      )}
      {modal.modal === "Adjudicators" && (
        <CollaboratorModal
          modalState={true}
          title="Adjudicators"
          collaborator="adjudicators"
          btnName="Add adjudicator"
        />
      )}
      {modal.modal === "Musicians" && (
        <CollaboratorModal
          modalState={true}
          title="Musicians"
          collaborator="musicians"
          btnName="Add musician"
        />
      )}
      {modal.modal === "Sponsors" && (
        <CollaboratorModal
          modalState={true}
          title="Sponsors"
          collaborator="sponsors"
          btnName="Add sponsor"
        />
      )}
      {modal.modal === "Examiner" && (
        <CollaboratorModal
          modalState={true}
          title="Examiners"
          collaborator="examiners"
          btnName="Add Examiner"
        />
      )}
      {modal.modal === "SelectOldAdjudicators" && (
        <SelectOldCollaboratorModal
          modalState={true}
          title="Adjudicators"
          btnName="Add new adjudicator"
        />
      )}
      {modal.modal === "SelectOldExaminers" && (
        <SelectOldCollaboratorModal
          modalState={true}
          title="Examiner"
          btnName="Add new Examiner"
        />
      )}
      {modal.modal === "SelectOldMusicians" && (
        <SelectOldCollaboratorModal
          modalState={true}
          title="Musicians"
          btnName="Add new musician"
        />
      )}
      {modal.modal === "SelectOldSponsors" && (
        <SelectOldCollaboratorModal
          modalState={true}
          title="Sponsors"
          btnName="Add new sponsor"
        />
      )}
      {modal.modal === "NewAdjudicators" && (
        <AddCollaboratorModal
          modalState={true}
          title="Adjudicator"
          btnName="adjudicator"
        />
      )}
      {modal.modal === "NewMusicians" && (
        <AddCollaboratorModal
          modalState={true}
          title="Musician"
          btnName="musician"
        />
      )}
      {modal.modal === "NewExaminer" && (
        <AddExaminerModal
          modalState={true}
          title="Examiner"
          btnName="Examiner"
        />
      )}
      {modal.modal === "NewSponsors" && (
        <AddCollaboratorModal
          modalState={true}
          title="Sponsor"
          btnName="sponsor"
        />
      )}
      {modal.modal === "Accomodation" && (
        <AccomodationModal modalState={true} />
      )}
      {modal.modal === "AddAccommodation" && (
        <AccomodationModel
          isManage={true}
          showAdjucator={false}
          from={ManageType}
        />
      )}
      {modal.modal === "AddExaminer" && (
        <AddExaminerModel isManage={true} showAdjucator={false} />
      )}
      {modal.modal === "ScheduledPdf" && (
        <ScheduledPdf
          modalState={true}
          name={syllabus}
          deleteFn={addScheduleToEventDetail}
        />
      )}
      {modal.modal === "ScheduleMaker" && (
        <AddStagesModal
          modalState={true}
          name={syllabus}
          deleteFn={addScheduleToEventDetail}
        />
      )}
    </>
  );
};

export default ManageFeisGrade;
