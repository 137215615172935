import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import closeIcon from "../../../Assets/close-icon.svg";
// import "./AddDancerModal.css";
import FormikControl from "../../../Components/formik/FormikControl";
import * as Yup from "yup";
import {
  Form,
  Formik,
  useFormikContext,
  label,
  Field,
  ErrorMessage,
} from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AdjucatorModel from "../../../Components/Modals/OrganizerModel/AdjucatorModel";

const AddExaminerModel = ({ action, UpdateDancer, AddAdjdis, }) => {
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  //Initial values for formik
  const addDancerInitialValues = {
    first_name: "",
    last_name: "",
    date_birth: "",
    gender: "",
    school: "",
    levels: "",
  };

  const addDancerValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .label("First Name:")
      .required("This field is required.")
      .nullable()
      .max(50),
    last_name: Yup.string()
      .trim()
      .label("Last Name:")
      .required("This field is required.")
      .nullable()
      .max(50),
    date_birth: Yup.string()
      .trim()
      .label("Birth Year:")
      .required("This field is required.")
      .matches(/^[0-9]{4}$/, "year must be 4 digits"),
    gender: Yup.string()
      .trim()
      .label("Gender:")
      .required("This field is required.")
      .nullable(),
    school: Yup.string()
      .trim()
      .label("School:")
      .required("This field is required.")
      .nullable(),
    levels: Yup.string()
      .trim()
      .label("Levels:")
      .required("This field is required.")
      .nullable(),
  });
  const disAddAj = () => {
    AddAdjdis(false);
  };
  const addDancer = async (values, formikHelpers) => {
    setLoading(true);
    let requestData = values;
    try {
      if (!values) {
        setValidation(false);
      }
      const firstName = requestData.first_name.charAt(0).toUpperCase() + requestData.first_name.slice(1, requestData.first_name.length);
      const lastName = requestData.last_name.charAt(0).toUpperCase() + requestData.last_name.slice(1, requestData.last_name.length);
      requestData = {
        ...requestData,
        first_name: firstName,
        last_name: lastName,
      };
      const url = `${process.env.REACT_APP_BASE_URL}/account/dancers`;
      const config = {
        headers: {
          Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
          accesstoken: localStorage.getItem("accessToken"),
        },
      };
      const response = await axios.post(url, requestData, config);
      if (response.status === 200) {
        action();
        UpdateDancer(true);
      }
    } catch (error) {
      setLoading(false);
      formikHelpers.setSubmitting(false);
    }
  };
  return (
    <Modal className="addDancerScroll" sx={{ display: "flex", alignItems: "center", justifyContent: "center", }} open={action}>
      <Box>
        <div style={{ maxHeight: "100vh", padding: "5px 100vw", overflow: "scroll", }}>
          <div className="main-div">
            <div className="close-div mt-4 mb-1">
              <img src={closeIcon} alt="close-icon" className="close-icon" onClick={disAddAj} />
            </div>
            <p className="color-subtitle text-center mb-4">Add Examiner</p>
            <div className="d-flex align-items-center justify-content-center" style={{ background: "white,borderRadius: 14px;" }}>
              <Formik
                initialValues={addDancerInitialValues}
                validationSchema={addDancerValidationSchema}
                onSubmit={addDancer}
              >
                {(formik) => (
                  <Form className="form form-label-right" autoComplete="off">
                    <label className="label-head-org">Name of Examiner: </label><br />
                    <Field className="input-box-org" type="text" name="Full name:" placeholder="Name" />
                    <ErrorMessage className="auth-error" name="password" component="div" /><br />
                    <label className="label-head-org">Photo: </label><br />
                    <Button
                      variant="outlined"
                      color="primary"
                      className="plusIconbtn"
                      sx={{
                        border: "2px solid",
                        fontSize: "16px",
                        border: "2px solid",
                        "&:hover": {
                          border: "2px solid #fbb040",
                          background: " #fbb040 ",
                          color: "#ffffff",
                          borderRadius: "2px solid #fbb040",
                        },
                      }}
                    >
                      <AddIcon id="plusICon" />
                      <span style={{ marginTop: "2px" }}>Add Photo</span>
                    </Button>
                    <div className="my-5 d-flex justify-content-center">
                      <button className={formik.isValid && formik.dirty ? "updateChange" : "updateChangeDisabled"}
                        style={{ fontWeight: "500" }}
                        type="submit"
                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                      >
                        Add Dancer
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddExaminerModel;
