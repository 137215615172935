// import React, { useState } from 'react'
// import ParentHeader from '../ParentHeader/ParentHeader'
// import Help from '../../Components/Help/Help'
// import { useLocation } from 'react-router-dom'
// import OneJudgeResult from './ResultTables/OneJudgeResult'
// import ThreeJudgeResult from './ResultTables/ThreeJudgeResult'
// import NineJudgeResult from './ResultTables/NineJudgeResult'
// import Loader from '../../Components/Loader/Loader'
// import { downloadPdf } from '../../helper/DownloadHandlers'
// import CommentBox from './CommentBox';
// import PrintResultsTitle from './PrintResultsTitle'

// const ViewCompleteResults = () => {
//     const [loading, setLoading] = useState(false)
//     const location = useLocation()
//     const stateObject = location.state
//     const adjudicators = stateObject.adjudicators
//     const sortedResults = stateObject.sortedResults
//     const rounds = stateObject.rounds
//     const keyProp = stateObject.keyProp
//     const compId = stateObject.compId
//     const event = stateObject.event
//     const competition = stateObject.competition
//     const dancerDetail = stateObject.dancerDetail

//     console.log(dancerDetail)

//     const downloadPdfHandler = () => {
//         const url = `${process.env.REACT_APP_BASE_URL}/feis/results/pdf/${compId}`;
//         downloadPdf(url, setLoading, event)
//     }

//     return (
//         <>
//             {loading && <Loader />}
//             <ParentHeader />
//             <div className="view-body">
//                 <PrintResultsTitle event={event} competition={competition} dancerFname={dancerDetail?.dancer_fname} dancerLname={dancerDetail?.dancer_lname} />
//                 <CommentBox scoresArr={dancerDetail?.scores} loading={loading} adjudicators={adjudicators} dancerDetail={dancerDetail} />
//                 <div className="event-judge-name mx-md-3" >Top Places</div>
//                 {adjudicators === 1 && <OneJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} completeResult="true" event={event} />}
//                 {adjudicators === 3 && <ThreeJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} completeResult="true" event={event} />}
//                 {adjudicators === 9 && <NineJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} completeResult="true" event={event} />}
//                 <button className="filled" onClick={downloadPdfHandler}>Download Pdf</button>
//             </div>
//             <Help />
//         </>
//     )
// }

// export default ViewCompleteResults