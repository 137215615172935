import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RegisteredGrade from "../../Register-Events/RegisteredGrade";
import Loader from "../../../Components/Loader/Loader";
import ParentHeader from "../../../Parent/ParentHeader/ParentHeader";
import { serviceConsumer } from "../../../network/ServiceConsumer";
import "./style.css";
import RegisterTitle from "../Components/RegisterTitle";
import { sortGrades } from "../../../helper/SortSyllabus";

const GradeEditEvent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPaymentPage, setshowPaymentPage] = useState(false);
  const [client, setClient] = useState("");
  const [errors, seterros] = useState("");
  const [grades, setGrades] = useState([]);
  const [checkedStateNew, setCheckedStateNew] = useState([]);
  const [dancerError, SetDancerError] = useState({ id: 0, message: "" });
  const [eventDetails, setEventDetails] = useState();
  const [prevTotal, setPrevTotal] = useState(0);
  const [prevFamilyFee, setPrevFamilyFee] = useState(0);
  const [initialLoading, setInitialLoading] = useState(false)
  const [prevDancerTotal, setPrevDancerTotal] = useState(0);

  // all dancer in an event
  const [allDancer, setAllDancer] = useState([]);

  // selected level's competitions list for all dancer, here one dancer in edit flow
  const [selectedGrades, setSelectedGrades] = useState([]);

  // selected competitions list for all dancer, here one dancer in edit flow
  const [previousCompetition, setPreviousCompetition] = useState([]);

  const searchParams = new URLSearchParams(window.location.search)
  const regId = searchParams.get('regId')
  const params = useParams();
  const eventId = params?.id

  // back button navigation based on 3 select, payment and error UI
  const backToViewEvent = () => {
    window.history.go(-1);
  };

  const backToRegisterGradeEvent = () => {
    showPaymentPage ? setshowPaymentPage(false) : window.history.go(-1);
  };

  const backToRegisterGradeEventFromErrorPage = () => {
    setshowPaymentPage(false);
    seterros("");
  };

  // get Registration Detail for 
  const getRegistrationDetail = async (grade_exam_fees) => {
    const url = `${process.env.REACT_APP_BASE_URL}/registration/grade/regdetails/${regId}`;
    try {
      setLoading(true);
      const response = await serviceConsumer('GET', url);
      if (response.result?.length) {
        const uniqueComp = [...new Set(response.result[0]?.grade_exam_fees)];
        setAllDancer([{
          _id: response.result[0]?.dancer_id,
          fname: response.result[0]?.dancerData?.fname,
          lname: response.result[0]?.dancerData?.lname,
          competitions: uniqueComp,
        }]);
        setPrevFamilyFee(response.result[0]?.fee);
        setPrevTotal(response.result[0]?.allLevelAmount)
        setPrevDancerTotal(response.result[0]?.dancerTotal)

        setCheckedStateNew([uniqueComp]);
        setPreviousCompetition(uniqueComp)

        const gradesList = grade_exam_fees.filter(grade => uniqueComp?.includes(grade._id))

        const dancerTotal = gradesList.reduce((res, { grade_fee }) => res + grade_fee, 0)

        const values = {
          grades: gradesList,
          dancer_id: response.result[0]?.dancer_id,
          dancerTotal,
          dancer_name: `${response.result[0]?.dancerData?.fname} ${response.result[0]?.dancerData?.lname}`
        };

        setSelectedGrades([values]);
      }
      setLoading(false);
    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setLoading(false);
    }
  };

  //navigating back to homepage if the url is misused
  useEffect(() => {
    getEventDetails();
    getAllGrades();
    document.title = "Feis.Link - Register Dancers"
  }, []);

  //  to get eventDetails
  const getEventDetails = async () => {
    setInitialLoading(true);
    try {
      if (eventId) {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.grade_exams);
      }

    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setInitialLoading(false);
    }
  };

  const getAllGrades = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${eventId}`;
    setInitialLoading(true);
    try {
      const response = await serviceConsumer('GET', url);
      if (response?.grade_exam_fees) {
        const sortedGrades = sortGrades(response.grade_exam_fees);
        setGrades(sortedGrades);
        getRegistrationDetail(sortedGrades)
      }

      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
    }
  }

  //checkbox and grade array handler
  const handleOnChange = async (index, dancer, grade) => {
    setLoading(true);
    const item = selectedGrades[index]?.grades?.find(it => it._id === grade._id);
    const dancer_name = `${dancer.fname} ${dancer.lname}`

    if (selectedGrades[index] && item) {
      removeGrade(index, dancer_name, dancer._id, grade._id)
    } else {
      let newSelectedList = [...selectedGrades];
      const gradesList = newSelectedList[index]?.grades?.length ? [...newSelectedList[index]?.grades, grade] : [grade]
      const dancerTotal = gradesList.reduce((res, { grade_fee }) => res + grade_fee, 0)
      const values = {
        grades: gradesList,
        dancer_id: dancer._id,
        dancerTotal,
        dancer_name: `${dancer.fname} ${dancer.lname}`
      };

      setSelectedGrades([values]);

      const newList = [...checkedStateNew];
      const checkedStateNewItem = newList[index] ? [...newList[index], grade._id] : [grade._id];
      newList[index] = checkedStateNewItem;
      setCheckedStateNew(newList);
    }

    setTimeout(() => {
      setLoading(false)
    }, 300);
  };

  const removeGrade = (index, dancer_name, dancer_id, gradeId) => {
    const newList = [...checkedStateNew];
    const checkedStateNewItem = newList[index]?.filter(item => item !== gradeId);
    newList[index] = checkedStateNewItem;
    setCheckedStateNew(newList);

    const newSelectedList = [...selectedGrades];
    const grades = selectedGrades[index]?.grades?.filter(item => item._id !== gradeId)
    const dancerTotal = grades?.reduce((res, { grade_fee }) => res + grade_fee, 0)

    const values = { grades, dancer_id, dancer_name, dancerTotal };

    newSelectedList[index] = values;

    setSelectedGrades(newSelectedList);
  };

  //condition to display payment page
  const setshowPaymentPageHandler = (val) => {
    setLoading(true);
    setshowPaymentPage(val);
  };

  const errormsg = (err) => {
    setshowPaymentPage(false);
    seterros(err);
    setLoading(false);
  };

  //client secret from stripe
  const clientSecret = (secret) => {
    setClient(secret);
    setLoading(false);
  };

  return (
    <div>
      <ParentHeader />
      {loading && <Loader />}
      {initialLoading && <Loader />}
      {!initialLoading && <div className="parent-register row">
        <div className="col-md-7 leftDiv">
          {/* select UI  */}
          {!showPaymentPage && !errors && (
            <>
              <RegisterTitle onClick={backToViewEvent} eventDetails={eventDetails} />
              <hr className="mt-0" />
              <div className="col-md-8 offset-md-2 offset-1 col-10 ">
                {allDancer?.map((dancer, index) => {
                  return (
                    <div key={dancer._id} className="my-5">
                      <h5>
                        Register dancer:&nbsp;
                        <span style={{ color: "#68ad3f" }}>{dancer?.fname} {dancer?.lname}</span>
                      </h5>
                      <hr className="mt-0"></hr>
                      <h6 style={{ color: "#68ad3f", marginTop: "10px", fontSize: "12px", }}>Choose Grade</h6>
                      <div
                        style={{ border: "2px solid #EAEAEA", padding: "5px", width: "fit-content", }}
                        className="chosegradee"
                      >
                        {grades?.map(grade => (
                          <div
                            className="btn-group btn-group-toggle action"
                            data-toggle="buttons"
                            style={{ margin: "5px" }}
                            key={grade._id}
                          >
                            <label
                              className={(checkedStateNew && checkedStateNew[index]?.includes(grade._id)) ? "btn activeBtn" : "btn"}
                            >
                              <input
                                type="checkbox"
                                checked={(checkedStateNew && checkedStateNew[index]?.includes(grade._id)) ? true : false}
                                onChange={() => handleOnChange(index, dancer, grade)}
                              />
                              {grade?.grade_name}
                            </label>{" "}
                          </div>
                        ))}
                      </div>
                      {dancer._id === dancerError.id && (<p className="error">{dancerError.message}</p>)}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {/* error page UI */}
          {errors && (
            <>
              <RegisterTitle onClick={backToRegisterGradeEventFromErrorPage} eventDetails={eventDetails} />
              <hr className="mt-0 " />
              <div className="col-md-8 offset-md-2 offset-1 col-10 ">
                <center><div style={{ color: "red" }}>{errors}</div></center>
              </div>
            </>
          )}
        </div>
        <div
          className="col-md-5 d-flex flex-column"
          style={{ background: "#fafafa", minHeight: "92vh", height: "fit-content", }}
        >
          <RegisteredGrade
            edit
            regId={regId}
            prevTotal={prevTotal}
            prevFamilyFee={prevFamilyFee}
            prevDancerTotal={prevDancerTotal}
            eventDetails={eventDetails}
            selectedGrades={selectedGrades}
            removeGrade={removeGrade}
            setshowPaymentPage={setshowPaymentPageHandler}
            clientSecret={clientSecret}
            errormsg={errormsg}
            showPaymentPage={showPaymentPage}
            previousCompetition={previousCompetition}
          />
        </div>
      </div>}
    </div>
  );
};

export default GradeEditEvent;
