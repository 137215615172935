import React from 'react';
import './Radio.css'; // Import the CSS file

const Radio = ({ label, checked, onChange, className }) => {
  return (
    <label className={`radioContainer ${className}`}>{label}
      <input type="radio" checked={checked} onChange={onChange} />
      <span className="radioCheckmark"></span>
    </label>
  );
};

export default Radio;