import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import congratulation from "../../Assets/Congratulations.png";
import feisLogo from "../../Assets/feis-logo.svg";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import "./style.css";
import axios from "axios";

const CongratratulationsGrade = ({ edit }) => {
  const { state } = useLocation()
  const isEditPage = state?.isEdit
  const eventName = state?.name
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [params, SetParams] = useSearchParams();
  const [event, setevent] = useState([]);
  const [counter, setCounter] = useState(0);
  const client_secret = params.get("payment_intent_client_secret");
  const paymentId = params.get("payment_intent");
  const [pay, setPay] = useState([]);

  const payload = {
    paymentId: paymentId,
  };

  const paymentPage = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/confirmpayment`;
    const config = {
      headers: {
        Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
        accesstoken: localStorage.getItem("accessToken"),
      },
    };
    setLoading(true)
    try {
      const res = await axios.post(url, payload, config);

      // if (res.data.response.status == "succeeded") {
      //   const payload = {
      //     paymentId: paymentId,
      //   };
      //   const config = {
      //     headers: {
      //       Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
      //       accesstoken: localStorage.getItem("accessToken"),
      //     },
      //   };
      //   const urls = edit ? `${process.env.REACT_APP_BASE_URL}/registration/edit/grade` : `${process.env.REACT_APP_BASE_URL}/registration/grade-exam`;
      //   const response = await axios.post(urls, payload, config);
      //   setevent(response.data.eventName)
      // }
      setPay(res.data.response.status);
      setLoading(false)
    } catch (err) {
      setevent(eventName)
      setLoading(false)
    }
  };

  setTimeout(() => {
    setCounter(10);
  }, 10000);

  useEffect(() => {
    if (paymentId) paymentPage();
    else setevent(eventName)
  }, [paymentId]);

  useEffect(() => {
    document.title = `Feis.Link - Success`
  })

  if (counter === 10) {
    navigate("/parent");
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      {loading && <Loader />}
      <div>
        <img
          src={feisLogo}
          alt=""
          className="m-4"
          style={{ cursor: "pointer", position: "absolute", top: "0", left: "0", }}
          onClick={() => navigate("/")}
        />
      </div>
      <br />
      <Container className="d-flex justify-content-center">
        <div className="d-flex justify-content-center container" style={{ alignItems: "center" }}>
          <div className="congratulations">
            <div className="text-center">
              <img src={congratulation} className="rounded" alt="..." />
              <h3>Congratulations!</h3>
              {!edit && <p className="title" style={{ fontSize: "16px", fontWeight: "400" }}>
                You have successfully registered for the
                <br />
                {event}
              </p>}
              {edit && <p className="title" style={{ fontSize: "16px", fontWeight: "400" }}>
                You registration has been edited.
              </p>}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CongratratulationsGrade;
