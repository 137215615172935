// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Rubik" !important;
  background-color: #FAFAFA;
}

.error {
  color: red;
  font-size: 12px;
}

.alertbutton {
  z-index: 9999;
  position: relative;
}
.checkbox {
  clip-path: circle(46% at 50% 50%);
}

.qnlogo {
  float: right;
  position: relative;
  bottom: 26vh;
  display: flex;
  right: 10vw;
  height: 73px;
}


.font-weight-bold{
  font-weight: 500;
}
body::-webkit-scrollbar {
  display: none !important;
}
@media only screen and (min-width: 0px) and (max-width: 481px) {
  .mainBtn {
    width: 21%;
    font-size: 16px !important;
    text-align: center;
    padding: 2px 11px !important
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .mainBtn {
    font-size: 18px !important;
    text-align: center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1200px) {
}
.mainBtn {
  font-size: 18px !important;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
  .mainBtn {

    font-size: 15px !important;
    text-align: center;
  }
}
@media screen and (min-width: 1900px) {
  .mainBtn {
    font-size: 18px !important;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE;IACE,UAAU;IACV,0BAA0B;IAC1B,kBAAkB;IAClB;EACF;AACF;AACA;EACE;IACE,0BAA0B;IAC1B,kBAAkB;EACpB;AACF;AACA;AACA;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE;;IAEE,0BAA0B;IAC1B,kBAAkB;EACpB;AACF;AACA;EACE;IACE,0BAA0B;IAC1B,kBAAkB;EACpB;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Rubik\" !important;\n  background-color: #FAFAFA;\n}\n\n.error {\n  color: red;\n  font-size: 12px;\n}\n\n.alertbutton {\n  z-index: 9999;\n  position: relative;\n}\n.checkbox {\n  clip-path: circle(46% at 50% 50%);\n}\n\n.qnlogo {\n  float: right;\n  position: relative;\n  bottom: 26vh;\n  display: flex;\n  right: 10vw;\n  height: 73px;\n}\n\n\n.font-weight-bold{\n  font-weight: 500;\n}\nbody::-webkit-scrollbar {\n  display: none !important;\n}\n@media only screen and (min-width: 0px) and (max-width: 481px) {\n  .mainBtn {\n    width: 21%;\n    font-size: 16px !important;\n    text-align: center;\n    padding: 2px 11px !important\n  }\n}\n@media only screen and (min-width: 481px) and (max-width: 767px) {\n  .mainBtn {\n    font-size: 18px !important;\n    text-align: center;\n  }\n}\n@media only screen and (min-width: 767px) and (max-width: 1200px) {\n}\n.mainBtn {\n  font-size: 18px !important;\n  text-align: center;\n}\n@media only screen and (min-width: 1200px) and (max-width: 1900px) {\n  .mainBtn {\n\n    font-size: 15px !important;\n    text-align: center;\n  }\n}\n@media screen and (min-width: 1900px) {\n  .mainBtn {\n    font-size: 18px !important;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
