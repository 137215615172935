import { toast } from 'react-toastify';

export const ShowToastMessage = (errorOrSuccess, message,time=1000) => {
    // for all toast msg the timing is 1000 except for zip file download(3000)
    errorOrSuccess === "error" ? toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    }) : toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    })
}
