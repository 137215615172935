import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import feisLogo from "../../Assets/feis-logo.svg";
import { Button } from "@mui/material";
import FormikControl from "../../Components/formik/FormikControl";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import "./style.css";
import axios from "axios";
import Help from "../../Components/Help/Help";
import { emailValidation } from "../../helper/Validations";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, SetError] = useState();
  const [suc, SetSuc] = useState();

  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate("/login");
  };
  const changeHandler = (e, email, setFieldValue) => {
    setFieldValue(email, e.target.value);
    SetError("");
  };
  //Initial values for formik
  const resetPasswordInitialValues = {
    email: "",
  };
  const goToConfirmPassword = () => {
    // navigate("/confirmPassword")
  };
  //Validation Schema for Formik
  const resetPasswordValidationSchema = Yup.object().shape({
    email: emailValidation,
  });
  const reset = async (value) => {
    SetError("");
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_BASE_URL}/forgot-pw`;
      const config = {
        headers: {
          Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
          accesstoken: localStorage.getItem("accessToken"),
        },
      };
      let email = value;
      const response = await axios.post(url, email, config);
      SetError("");
      SetSuc("An email has been sent with the instructions to reset your password");
      setLoading(false);
    } catch (error) {
      SetError("");
      SetError(error.response.data.message);
      setLoading(false);
      SetSuc("");
    }
  };

  useEffect(() => {
    document.title = "Feis.Link - Forgot Password"
  }, [])
  return (
    <div className="d-flex flex-column justify-content-between" style={{ height: "100vh" }}>
      {loading && <Loader />}
      <div>
        <img
          src={feisLogo}
          alt=""
          className="m-4 cursor-pointer"
          onClick={() => { navigate("/"); }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Formik
          initialValues={resetPasswordInitialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={reset}
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="">
                <div className="email-form-reset">
                  <div style={{ marginBottom: "44px" }}>
                    {suc?.length > 0 ? (<Alert sx={{ color: "#68AD3F" }} icon={false} severity="success">{suc}</Alert>) : ("")}
                    {error?.length ? (<Alert style={{ color: "error" }} icon={false} severity="error" >{error}</Alert>) : ("")}
                  </div>
                  <h4 className="title-resetPwd" style={{ color: "#323232" }}>Recover your password</h4>
                  <p className="subtitle-resetPwd" style={{ color: "#323232" }}>
                    Enter the email address associated with your account
                  </p>
                  <div>
                    <FormikControl
                      className="email-password-reset"
                      control="input"
                      type="text"
                      name="email"
                      placeHolder="Email"
                      style={{ padding: "9px 20px" }}
                      onChange={(e) => { changeHandler(e, "email", formik.setFieldValue); }}
                    />
                  </div>
                  <br />
                  <div className="mt-2">
                    <button className="filled" type="submit" onClick={() => goToConfirmPassword()}>Next</button>
                  </div>
                  <br /> <hr /> <br />
                  <div className="d-flex justify-content-center">
                    <p className="reset-pwd-login-button" onClick={goToLoginPage} style={{ color: "#323232" }}>Login</p>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Help />
    </div>
  );
};

export default ResetPassword;
