import React, { useState, useEffect } from "react";
import moment from "moment";
import Loader from "../Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer";
import DashboardCards from "../../Parent/Dashboard/DashboardCards";
import HomePageCards from "../../Feis-Homepage/HomePageCards";
import "./style.css";

// Both homepage and dashboard cards call the same apis,same component is used .But view of both differs.
// - homepage shows only 7 cards and allows to search through all components
// -dashboard provides infinite scroll but no search. TTherefore 2 views
// so the api result is again given to both  for different views

const Card = ({ isHomePage, searchInput, eventMode }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const limit = isHomePage ? 1000 : 5;
  const date = moment(new Date()).utc(true).format();

  const apiEventMode = eventMode === "open" ? "getOpenEvents"
    : (eventMode === "closed") ? "getCloseEvents"
      : (eventMode === "result") ? "getResultsEvents" : "getupcomingEvents"

  const errorMsg = eventMode === "open" ? "No open events"
    : (eventMode === "closed") ? "No closed events"
      : (eventMode === "result") ? "No results" : "No upcoming events"

  useEffect(() => {
    setLoading(true)
    getAllEvents();
  }, []);



  // get all open events
  const getAllEvents = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/${apiEventMode}`;
    const payload = { page, limit, date };
    try {
      const response = await serviceConsumer("POST", url, payload);
      if (response?.Feis?.results.length > 0) {
        const finishedEvents = response?.Feis?.results;
        setEvents((prev) => [...prev, ...finishedEvents]);
        setHasMore(response?.Feis?.next?.hasMore);
        response?.Feis?.next?.hasMore
          ? setPage(response?.Feis?.next?.page)
          : setPage(page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && events.length === 0 && (
        <div className="d-flex flex-grow-1 justify-content-center my-5 OpenEVent">{errorMsg}</div>
      )}
      {!loading && events.length > 0 && (
        <div>
          {!isHomePage && <DashboardCards events={events} getAllEvents={getAllEvents} hasMore={hasMore} from={eventMode} />}
          {isHomePage && <HomePageCards events={events} from={eventMode} searchInput={searchInput} />}
        </div>
      )}
    </>
  );
};
export default Card;
