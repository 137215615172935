import React from 'react'
import WebCard from '../Components/Card/WebCard';
import MobileCard from '../Components/Card/MobileCard';

const HomePageCards = ({ events, searchInput, from }) => {
    const filteredevents = events.filter((searching) =>
        searchInput === "" ? searching : searching.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    return (
        <>
            {filteredevents?.length === 0 ? (
                <div className=" d-flex justify-content-center" style={{ margin: "100px 0" }}>
                    There are no events matching your search.
                </div>
            ) : (
                filteredevents?.slice(0, 7).map((event, index) => {
                    return (
                        <div className="register-events" key={event._id} data-value={event._id} >
                            < WebCard event={event} from={from} isHomePage={true} />
                            <MobileCard event={event} from={from} isHomePage={true} />
                        </div>
                    );
                })
            )}
        </>
    )
}

export default HomePageCards