import React, { useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { GoogleMap, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '20vh',
  width: '100%',
  marginTop: '12px',
};

const LoadScript = ({ lat, lon, zipcode, address, region }) => {
  const center = { lat: parseFloat(lat), lng: parseFloat(lon) };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API, // ,
    // ...otherOptions
  });
  const [updatedLocDetails, setLocationDetails] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lon),
  });
  const mapClickHandler = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}${region}${zipcode}`;
    window.open(mapsUrl, '_blank');
  };
  return (
    <>
      <GoogleMap
        id="circle-example"
        mapContainerStyle={mapContainerStyle}
        defaultOptions={{
          mapTypeControl: false,
          fullscreenControl: true,
        }}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          draggable: true,
        }}
        zoom={13}
        center={center}
      >
        {' '}
        <Marker position={center} />
        <div
          onClick={mapClickHandler}
          style={{
            cursor: 'pointer',
            color: 'blue',
            fontSize: '12px',
            position: 'absolute',
            fontWeight: 500,
            color: 'blue',
            fontSize: '12px',
            position: 'absolute',
            fontWeight: 500,
            background: ' #d1c9c9',
            opacity: ' 0.7',
            color: ' #000000',
            padding: '0px 0px 0px 8px',
          }}
        >
          View larger map
        </div>
      </GoogleMap>
    </>
  );
};

export default LoadScript;
