export const CalculateCount = (event, sortedResults) => {
    //minimum number to be in top places
    const minNumToAward = event.minimumNumberToAward;
    //when competitors exceeds the min amount , the percentage of competitors takes the top place
    const precentage = event.percentageToAward;

    const competitorsCount = sortedResults.length

    if (competitorsCount <= minNumToAward) {
        return competitorsCount
    } else {
        const count = Math.round((precentage / 100) * competitorsCount);
        const topPlaces = sortedResults.filter(result => result.overallPosition <= sortedResults[count - 1].overallPosition)
        return topPlaces.length;
    }
}
