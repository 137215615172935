  import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../Button/Button';
import Modal from '@mui/material/Modal';
import closeIcon from '../../Assets/close-icon.svg';
import createNew from '../../Assets/create-new-event.svg';
import createNewEnabled from '../../Assets/create-new-enabled.svg';
import prevEvent from '../../Assets/prev-event.svg';
import prevEventEnabled from '../../Assets/prev-event-enabled.svg';
import './OrganizerModal.css';
import './AddDancerModal.css';

const CreateEventModal = ({ modalState, clickHandler, eventType }) => {
  const [enableButton, setEnableButton] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const navigate = useNavigate();
  const modalCloseHandler = () => {
    clickHandler();
    setEnableButton(false);
  };

  // create new event for feis and grade
  const createNewHandler = () => {
    setActiveButton('createNew');
    setEnableButton(true);
    localStorage.removeItem('ID');
    localStorage.removeItem('TAB');
    localStorage.setItem("redirectFeis","create")
  };

  //create a new event with details from previous event for feis and grade
  const createFromPrevHandler = () => {
    setActiveButton('createFromPrev');
    setEnableButton(true);
  };

  //next button
  const nextButtonHandler = () => {
    activeButton === 'createFromPrev' && navigate('/previous-events', { state: { eventType } });
    activeButton === 'createNew' && eventType === 'feiseanna' &&navigate('/organizer-create-feisanna');
    activeButton === 'createNew' &&eventType === 'grade' &&navigate('/organizer-create-grade');
  };

  return (
    <div>
      <Modal sx={{ display: 'flex',alignItems: 'center',justifyContent: 'center',}}open={modalState}>
        <div className="org-modal-div">
          <div className="close-div mb-1">
            <img src={closeIcon} alt="close-icon" className="close-icon"onClick={modalCloseHandler}/>
          </div>
          <p className="modal-org-title text-center ">Select</p>
          <div className="d-flex flex-column align-items-center justify-content-center ">
            <div className="create-event-btns" onClick={createNewHandler}>
              <img src={activeButton === 'createNew' ? createNewEnabled : createNew}alt=""/>
              <div>Create New</div>
            </div>
            <div className="create-event-btns" onClick={createFromPrevHandler}>
              <img src={activeButton === 'createFromPrev'? prevEventEnabled: prevEvent} alt=""/>
              <div>Previous Event</div>
            </div>
            <Button
              className={ enableButton ? 'filled mt-3 mb-5' : 'disabled mt-3 mb-5'}
              disabled={!enableButton}
              onClick={nextButtonHandler}
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateEventModal;
