import React from 'react';
import "./Checkbox.css"
import { CustomTooltip } from '../Tooltip/CustomToolTip';

const Checkbox = ({ onChange, checked, name, disabled, classCheck, tooltip }) => {
    const content = checked ? "Award has been collected" : "Click to mark as collected";

    return (
        // The containerWithoutLeft is only for awardsTable
        <label className={disabled ? "check-container-disabled" : classCheck ? "containerWithoutLeft check-container" : "check-container"}>
            <input type="checkbox" onChange={onChange} checked={checked} name={name} disabled={disabled} />
            {tooltip
                ? <CustomTooltip
                    title={content} arrow>
                    <span className="checkmark" ></span>
                </CustomTooltip>
                : <span className="checkmark" ></span>}
        </label >
    )
}

export default Checkbox


