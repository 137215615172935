import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import closeIcon from '../../../Assets/close-icon.svg';
import FormikControl from '../../formik/FormikControl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import AddAdjucatorModel from './AddAdjucatorModel';
import AddButton from '../../AddButton/AddButton';
import { serviceConsumer } from '../../../network/ServiceConsumer';

const AdjucatorModel = ({ modalState, action, showAdjucator, getAdjucators, adjucator, from }) => {
  let EventID = localStorage.getItem('ID');
  const [accomodadator, setAccomodator] = useState([]);
  const [OpenModalAdjucator, setOpenModalAdjucator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAdj, setShowAdj] = useState(showAdjucator);

  useEffect(() => {
    getAccomodationData();
  }, []);

  const getAccomodationData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/people/adjudicator`;
      const response = await serviceConsumer('GET', url);
      setAccomodator(response.people);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  const accData = accomodadator.map((acc) => {
    return {
      key: acc.name,
      value: acc._id,
    };
  });

  const handleOpenModalAdjucator = () => {
    setOpenModalAdjucator(!OpenModalAdjucator);
  };

  const closeParentModel = (val) => {
    setShowAdj(val);
    showAdjucator = true;
    if (showAdjucator === true) {
      setShowAdj(true);
    }
    setOpenModalAdjucator(OpenModalAdjucator);
    if (showAdjucator === true) {
    }
  };

  //Initial values for formik
  const addDancerInitialValues = {
    Adjudicator: '',
  };

  //Validation Schema for Formik
  let adjudicators = [];

  const addDancerValidationSchema = Yup.object().shape({
    Adjudicator: Yup.string()
      .trim()
      .label('First Name:')
      .required('This field is required.')
      .nullable()
      .max(50),
  });

  // submit Handler
  const addAdjucatorHandler = async (values) => {
    const feisUrl = `${process.env.REACT_APP_BASE_URL}/people/adjudicator `;
    const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/adjudicator `;
    const url = from === "feis" ? feisUrl : gradeUrl;
    const payload = {
      eventId: EventID,
      peopleId: values.Adjudicator,
    };
    try {
      setLoading(true);
      const response = await serviceConsumer('POST', url, payload);
      if (response.success === true) {
        action();
        getAdjucators()
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const AddAdjdis = (val) => {
    setShowAdj(val);
  };

  return (
    <div style={{ padding: '100px', overflow: 'scroll' }}>
      {showAdj === false && (
        <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={modalState}>
          <Box>
            <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
              <div className="main-div-adj">
                <div className="close-div mt-4 mb-1">
                  <img src={closeIcon} alt="close-icon" className="close-icon" onClick={action} />
                </div>
                <p className="color-subtitle text-center mb-4"> Add Adjudicator</p>
                <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                  <Formik
                    initialValues={addDancerInitialValues}
                    validationSchema={addDancerValidationSchema}
                    onSubmit={addAdjucatorHandler}
                  >
                    {(formik) => (
                      <Form
                        className="form form-label-right"
                        autoComplete="off"
                      >
                        <div className={(formik.errors?.Adjudicator && formik.touched?.Adjudicator) ? '' : 'input-control'}>
                          <label className={(formik.touched?.Adjudicator && formik.errors?.Adjudicator) ? 'select-label-error' : 'select-label'} htmlFor='input-box-org-select'>Adjudicator:</label>
                          <FormikControl
                            className={(formik.touched?.Adjudicator && formik.errors?.Adjudicator) ? "input-box-org-select-error" : "input-box-org-select"}
                            control="select"
                            name="Adjudicator"
                            options={accData}
                            showAs="Select"
                            style={{ color: 'black' }}
                            id="AddAdjucator"
                            setFieldValue={formik.setFieldValue}
                          />
                        </div>
                        <span className="DidnontFind"> Didn't find the Adjudicator?</span>

                        <div className="d-flex justify-content-center mt-4 ">
                          <AddButton className="plusIconbtn" onClick={closeParentModel} from="collab">
                            Add new adjudicator
                          </AddButton>
                        </div>
                        <div className="mb-5 d-flex justify-content-center">
                          <button className={formik.isValid && formik.dirty ? 'filled' : 'disabled'}
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                          >
                            Continue
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {showAdj === true && (
        <AddAdjucatorModel
          action={handleOpenModalAdjucator}
          setShowAdj={setShowAdj}
          AddAdjdis={AddAdjdis}
          adjudicators={adjudicators}
          adjucator={adjucator}
          getAdjucators={getAdjucators}
          handleOpenModal={action}
        />
      )}
    </div>
  );
};

export default AdjucatorModel;
