import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ParentHeader from '../ParentHeader/ParentHeader';
import Loader from '../../Components/Loader/Loader';
import moment from 'moment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import favIcon from '../../Assets/favicon.ico';
import { serviceConsumer } from '../../network/ServiceConsumer';
import CollaboratorsList from '../Components/CollaboratorsList';
import LocationDetails from '../Components/LocationDetails';
import Help from '../../Components/Help/Help';
import AccommodationsList from '../Components/AccommodationsList';
import GradeTable from '../Components/GradeTable';
import RegisteredDancers from '../Components/RegisteredDancers';
import { formatDate } from '../../helper/formatDate';
import { useDispatch } from 'react-redux';
import { setConversionRate } from '../Register-Events/helper';


const ViewGradeEdit = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState()
  const [collaborators, setCollaborators] = useState([])
  const [dancers, setDancers] = useState([])

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const eventId = searchParams.get('id')

  const getAllDetails = async () => {
    try {
      setLoading(true)
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/registered/dancers/${eventId}`
      const response = await serviceConsumer("GET", url)
      setConversionRate(dispatch,response?.eventData?.usdExchangeRate)
      setEvent(response?.eventData)
      setDancers(response?.result)

      const collaboratorsUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/collaborators/${eventId}`
      const collaboratorsResponse = await serviceConsumer('GET', collaboratorsUrl)
      setCollaborators(collaboratorsResponse.result)
      setLoading(false)
    }
    catch (error) {
      navigate("pageNotFound", { replace: true })
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Feis.Link - ${eventProp?.result?.name}`;
    getAllDetails()
  }, []);

  //getting passed eventprops from card
  let eventProp = state?.eventProp;

  //button handlers
  const backToViewEvent = () => {
    navigate('/parent');
  };

  const viewSyllabus = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const printScheduleHandler = () => {
    window.open(eventProp.result.schedulePdfUrl, '_blank', 'noopener,noreferrer');
  }
  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <ParentHeader />
      {loading && <Loader />}
      {!loading && <>
        <div className="d-flex justify-content-center  view-header">
          <div className="d-flex  flex-grow-1 ">
            <div className="flex-grow-1">
              <div
                className="d-flex align-items-center"
                onClick={backToViewEvent}
                style={{ color: '#68AD3F', cursor: 'pointer', width: 'fit-content', }}
              >
                <div><NavigateNextIcon style={{ transform: 'rotate(180deg)' }} /></div>
                <div style={{ marginLeft: '5px', fontWeight: '500', fontSize: '16px', marginTop: '2px', }}>Back</div>
              </div>
              <div className="d-flex header-mobile">
                <div className="d-flex flex-grow-1 align-items-center">
                  <img src={eventProp?.result?.logo || favIcon} className="event-img-view" alt="logo" />
                  <div className="registerevent-details d-flex flex-grow-1 align-items-center">
                    <div>
                      <p className="title m-1">{eventProp?.result?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"} - Entered</p>
                      <p className="event-name m-1">{eventProp?.result?.name}</p>
                      <p className="reg-date-open m-1">
                        {(eventProp?.result?.eventType === 'Feiseanna' ? eventProp?.result?.feis_date : eventProp?.result?.exam_date)
                          ? moment(formatDate(eventProp?.result?.eventType === 'Feiseanna' ? eventProp?.result?.feis_date : eventProp?.result?.exam_date)).format(' MMMM Do, YYYY')
                          : 'NA'}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-body">
          <div className="parent-register row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 leftBlock">
              <RegisteredDancers event={event} dancers={dancers} eventType="grade" />
              <p className="pageHeaders">Description</p>
              <hr className="mt-0" />
              <p className="description">
                {eventProp?.result?.description} <br />
              </p>
              <div className="d-flex gap-2">
                {eventProp?.result?.syllabus_file && <button className="outlined" onClick={() => viewSyllabus(eventProp?.result?.syllabus_file)} >  Grades</button>}
                {eventProp?.result?.schedulePdfUrl && <button className="outlined" onClick={printScheduleHandler}> Schedule </button>}
              </div>

              <CollaboratorsList collaborators={collaborators} event="grade" />
              <GradeTable grades={collaborators?.grades} />
            </div>
            <br></br>
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-9">
              <LocationDetails event={event} />
              <br></br>
              <Help />
              <AccommodationsList accomodations={event?.accomodations} />
            </div>
          </div>
        </div>
      </>}
    </div >
  );
};

export default ViewGradeEdit;
