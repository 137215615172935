import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg"
import { serviceConsumer } from "../../../../../network/ServiceConsumer"
import Loader from '../../../../Loader/Loader';

const ConfirmDeleteModal = ({ modalState, confirm, cancel, adjudicator, compId, card, competitors, results,dancerPage }) => {
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("");
    const createOrEdit = localStorage.getItem("resultStatus")

    const deleteDancerHandler = async () => {
        if (dancerPage) {
            confirm();
            return;
        }
        const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/delete/scores`;

        const payload = {
            card: card,
            competition_id: compId,
            judge_id: adjudicator
        };
        if (card == "" || createOrEdit === "create") {
            confirm()
        }

        else if (competitors?.some(item => item.card_number == card) || (results[0]?.competitors?.some(item => item.card == card))) {
            try {
                setLoading(true)
                const response = await serviceConsumer("POST", url, payload);
                setLoading(false)
                confirm();
            } catch (error) {
                setErr(error.messages)
                setLoading(false);
            }
        }
        else {
            confirm()
        }
    }

    return (
        <>
            {loading && <Loader />}
            <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
                <div className="org-modal-div merge-modal-div" >
                    <div className="close-div mb-1" style={{ padding: "0px 10px 0px 0px", marginLeft: "25px" }}>
                        <img src={closeIcon} alt="close-icon" className="close-icon" onClick={cancel} />
                    </div>
                    <div style={{ width: "250px" }}>
                        <div className="mt-3">
                            <p className="modal-org-subtitle text-center">{`Are you sure you want to delete ?`}</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3 mt-4">
                            <div>
                                <button className="filled" onClick={deleteDancerHandler} >Delete</button>
                            </div>
                            <div>
                                <button className="filled" onClick={cancel}>Cancel</button>
                            </div>
                        </div>
                        {err && <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>{err}</div>}
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default ConfirmDeleteModal