import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { serviceConsumer } from '../../../../network/ServiceConsumer'
import ManageTitle from '../../../../Components/TitleElements/ManageTitle'
import HeaderOrg from '../../../Headers/HeaderOrg'
import Loader from '../../../../Components/Loader/Loader'
import Help from '../../../../Components/Help/Help'
import sort from "../../../../Assets/managefeisGrade/sort-org.svg"
import edit from "../../../../Assets/edit-comp-action.svg"
import "../../../../Components/Table.css"
import { groupAndSortComp } from '../../../../helper/SortSyllabus'

const MergeSplit = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const [event, setEvent] = useState()
    const [loading, setLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [responseArray, setResponseArray] = useState([])
    const [tableArr, setTableArr] = useState([])
    const [sorterData, setSorterData] = useState("")
    const [sortOrder, setSortOrder] = useState("asc")
    const [err, setErr] = useState("")

    //get eventDetails for title and competition details for table
    const getAllDetails = async () => {
        try {
            setLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)
            const competitionUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/competition`
            const competitionResponse = await serviceConsumer('GET', competitionUrl)
            const sortedCompetitions = groupAndSortComp(competitionResponse.competitions)
            setResponseArray(sortedCompetitions)
            setLoading(false)
        }
        catch (error) {
            navigate("/pageNotFound", { replace: true })
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllDetails()
    }, [])

    // search input in competitors
    useEffect(() => {
        const tableArr = responseArray?.filter((searching) => {
            if (searchInput === "") {
                return searching;
            } else if (
                (searching?.code?.toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.group?.toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.ages?.map(age => age.value).toString().toLowerCase().includes(searchInput?.toLowerCase())
                    || searching?.dancers?.length.toString().includes(searchInput?.toLowerCase())
                )
            ) {
                return searching;
            }
        })
        setTableArr(tableArr)
    }, [responseArray, searchInput])

    //sort competitor as per column
    const sortCompetitorsHandler = (sorter) => {
        let order = sorter === sorterData ? (sortOrder === "asc" ? "desc" : "asc") : "asc";
        order === "asc" && sorter === "competition" && tableArr.sort((a, b) => a.code.localeCompare(b.code))
        order === "asc" && sorter === "level" && tableArr.sort((a, b) => a.group.localeCompare(b.group))
        order === "asc" && sorter === "entriesMax" && tableArr.sort((a, b) => a.dancers.length - (b.dancers.length))
        order === "asc" && sorter === "ages" && tableArr.sort((a, b) => (Number(a.ages[0].value.substring(1))) - (Number(b.ages[0].value.substring(1))))


        order === "desc" && sorter === "competition" && tableArr.sort((a, b) => b.code.localeCompare(a.code));
        order === "desc" && sorter === "level" && tableArr.sort((a, b) => b.group.localeCompare(a.group));
        order === "desc" && sorter === "entriesMax" && tableArr.sort((a, b) => b.dancers.length - (a.dancers.length));
        order === "desc" && sorter === "ages" && tableArr.sort((a, b) => (Number(b.ages[0].value.substring(1))) - (Number(a.ages[0].value.substring(1))))

        setTableArr(tableArr)
        setSorterData(sorter)
        setSortOrder(order)
    }

    //edit image click handler
    const editClickHandler = (competition) => {
        navigate(`/merge-split-comp/${event?.name}?id=${event?._id}&competitionId=${competition._id}`)
    }
    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={loading} />
            <div className="db-body">
                {!loading && <>
                    <div className='org-body-title pt-4'>Merge and splits</div>
                    <div className="mb-3 hr-org-body" />
                    <div className="search-label" style={{ marginTop: "33px" }}>Search:</div>
                    <input
                        type="text"
                        name="search"
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Search..."
                        className="searchFeisComp"
                    />
                    <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px" }}>
                        <table className="table my_table competitorTable ">
                            <tbody>
                                <tr className="header-row">
                                    <td className="ps-3 w-20 p-25-5">Competitions&nbsp;<span>
                                        <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("competition")} />
                                    </span></td>
                                    <td className=" w-20 p-25-5">Level &nbsp;<span>
                                        <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("level")} />
                                    </span></td>
                                    <td className=" w-20 p-25-5">Ages &nbsp;<span>
                                        <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("ages")} />
                                    </span></td>
                                    <td className=" w-20 p-25-5 text-center"># Entries &nbsp;<span>
                                        <img className="cursor-pointer" src={sort} alt="" onClick={() => sortCompetitorsHandler("entriesMax")} />
                                    </span></td>
                                    <td className="px-0 w-20 p-25-5 text-center">Merge and split</td>
                                </tr>
                                {tableArr?.filter(comp => comp.dancers.length > 0).map((competition, index) => {
                                    let background = competition.dancers.length > 15 ? "#FFE1E1" : "#ffffff";
                                    return <tr key={index} style={{ background: background, fontSize: "12px", height: "40px" }}>
                                        <td className=" ps-3 w-20 grey-30"><div>{competition?.code}-{competition?.description}<div>{competition?._id}</div></div></td>
                                        <td className=" w-20 grey-30">{competition?.group}</td>
                                        <td className=" w-20 grey-30">{competition?.ages?.map((item, index) => { return (competition?.ages?.length === index + 1) ? item : `${item}, ` })}</td>
                                        <td className=" w-20 grey-30 text-center">{competition?.dancers?.length}</td>
                                        <td className=" px-0 w-20 grey-30 text-center"><img className="cursor-pointer" onClick={() => editClickHandler(competition)} src={edit} alt="edit" /></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {!loading && responseArray.length > 0 && tableArr?.length === 0 && searchInput !== "" && <div className="d-flex justify-content-center my-5">There are no competitors matching your search</div>}
                        {!loading && responseArray.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>}
                        {!loading && err && <div className="d-flex justify-content-center error-class">{err}</div>}
                    </div>
                </>}
            </div>
            <Help />
        </>
    )
}

export default MergeSplit