// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prev-page-title{
    padding: 0 7%;
    margin-top:1rem;
    display:flex;
}
.prev-evt-head{
font-weight: 400;
font-size: 24px;
color: #424242;
margin-left: 3rem;
}
.tabs-prev{
    margin-bottom:1px solid gray
}
.db-tab-container .MuiTabs-flexContainer{
justify-content: flex-start;
}
.db-tab-container .MuiTabs-indicator{
    height:3px;
    border-radius:4px;
}
@media only screen and (min-width: 0px) and (max-width: 480px) {
    .prev-page-title{
        padding: 0 5%;
        margin-top:1rem;
        flex-direction:column;
    }
    .prev-evt-head{
        margin-left: 0;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .prev-page-title{
        padding: 0 5%;
        margin-top:1rem;
        flex-direction:column;
    }
    .prev-evt-head{
        margin-left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/Organizer/CreateEventFromPrev/CreateEvent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,YAAY;AAChB;AACA;AACA,gBAAgB;AAChB,eAAe;AACf,cAAc;AACd,iBAAiB;AACjB;AACA;IACI;AACJ;AACA;AACA,2BAA2B;AAC3B;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;AACA;IACI;QACI,aAAa;QACb,eAAe;QACf,qBAAqB;IACzB;IACA;QACI,cAAc;IAClB;AACJ;AACA;IACI;QACI,aAAa;QACb,eAAe;QACf,qBAAqB;IACzB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".prev-page-title{\n    padding: 0 7%;\n    margin-top:1rem;\n    display:flex;\n}\n.prev-evt-head{\nfont-weight: 400;\nfont-size: 24px;\ncolor: #424242;\nmargin-left: 3rem;\n}\n.tabs-prev{\n    margin-bottom:1px solid gray\n}\n.db-tab-container .MuiTabs-flexContainer{\njustify-content: flex-start;\n}\n.db-tab-container .MuiTabs-indicator{\n    height:3px;\n    border-radius:4px;\n}\n@media only screen and (min-width: 0px) and (max-width: 480px) {\n    .prev-page-title{\n        padding: 0 5%;\n        margin-top:1rem;\n        flex-direction:column;\n    }\n    .prev-evt-head{\n        margin-left: 0;\n    }\n}\n@media only screen and (min-width: 481px) and (max-width: 767px) {\n    .prev-page-title{\n        padding: 0 5%;\n        margin-top:1rem;\n        flex-direction:column;\n    }\n    .prev-evt-head{\n        margin-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
