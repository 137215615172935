import React, { useState, useRef } from 'react'
import { Formik, Field, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { serviceConsumer } from '../../../../network/ServiceConsumer';
import AddButton from "../../../../Components/AddButton/AddButton"
import Loader from '../../../../Components/Loader/Loader';
import ConfirmDeleteModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import "./ResultTable.css"
import "../../../../Components/Table.css"
import { CommentTd, DeleteIconTd, NumberInputTd } from './Component/CommonTableTds';

function OneRoundTable({ competitors, adjudicator, compId, event, keyProp, errProp, results, adjudicatorCount, round, getCompetitors, propLoading }) {
  const navigate = useNavigate()
  const addNewRef = useRef(null)
  const saveRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [deletionIndex, setDeletionIndex] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [err, setErr] = useState(errProp)
  const [cardNum, setCardNum] = useState()
  // let arr = [1]

  const initialValues = results.length > 0 ? {
    tableData: results.map((competitor) => {
      return {
        judge_id: adjudicator,
        dancer_id: competitor.scoresArray[0].dancer_id,
        card_number: competitor.card_number,
        round: adjudicatorCount === 9 ? round : 1,
        score: competitor.scoresArray[0].score,
        comments: competitor.scoresArray[0].comments,
      }
    }),
  } : {
    tableData: competitors.map((competitor) => {
      return {
        judge_id: adjudicator,
        dancer_id: "",
        card_number: "",
        round: adjudicatorCount === 9 ? round : 1,
        score: "",
        comments: "",
      }
    })
  }

  const validationSchema = Yup.object().shape({
    tableData: Yup.array().of(
      Yup.object().shape({
        card_number: Yup.number()
          .required('required!')
          .test('is-valid-card-number', 'Invalid card number', function (value) {
            if (results.length <= 0) {
              return competitors?.some(item => item.card_number == value);
            } else {
              return results[0]?.competitors?.some(item => item.card == value);
            }
          }),
        round: Yup.string().required('required!'),
        // comments: Yup.string().required('required!'),
        score: Yup.number()
          .required('required!')
          .min(0, 'Score must be at least 0')
          .max(100, 'Score must be at most 100'),
      })
    ),
  });
  const handleSubmit = async (values) => {
    const changedTable = values.tableData.map((item, index) => {
      const foundCompetitor = results.length <= 0
        ? competitors.find(competitor => competitor.card_number == item.card_number)
        : results[0]?.competitors?.find(competitor => competitor.card == item.card_number);
      let dancerId;
      if (results.length <= 0) dancerId = foundCompetitor ? foundCompetitor.dancerData._id : null
      else dancerId = foundCompetitor ? foundCompetitor.dancerId : null

      return {
        ...item,
        sNo: index + 1,
        card_number: Number(item.card_number),
        dancer_id: dancerId,
      };
    });

    const cardNumbersSet = new Set();
    let duplicateFound = false;
    for (const item of changedTable) {
      if (cardNumbersSet.has(item.card_number)) {
        setErr("Dancers repeated")
        duplicateFound = true;
        break;
      } else {
        cardNumbersSet.add(item.card_number);
      }
    }
    if (!duplicateFound) {
      try {
        setLoading(true)
        const payload = { results: changedTable }
        const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/${compId}`;
        await serviceConsumer("POST", url, payload)
        navigate(`/complete-results/${event?.name}?id=${event?._id}&compId=${compId}`)
        setLoading(false)
      }
      catch (error) {
        setErr(error.messages)
        setLoading(false)
      }
    }
  };


  const addNewButtonHandler = () => {
    addNewRef.current && addNewRef.current.click && addNewRef.current.click()
  }

  //functions to handle modal
  const openConfirmModal = (index, cardNum, dancerId) => {
    setDeletionIndex(index);
    setCardNum(cardNum)
    setShowConfirmModal(true);
  };

  const handleDeletionConfirmed = (remove) => {
    remove(deletionIndex);
    getCompetitors(compId);
    setShowConfirmModal(false);
    setErr("")
  };

  const handleDeletionCancelled = () => {
    setShowConfirmModal(false);
  };

  const newRowsHandler = (values, setFieldValue, push) => {
    push({ card_number: '', round: '', comments: "" });
    setFieldValue('tableData', [
      ...values.tableData,
      { judge_id: adjudicator, dancer_id: '', card_number: '', round: adjudicatorCount === 9 ? round : 1, score: '', comments: '' },
    ]);
  }

  return (
    <>
      {loading && <Loader />}
      <Formik
        key={keyProp}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form onChange={() => setErr("")} autoComplete="off">
            <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px" }} >
              <table className="table my_table competitorTable mb-0">
                <FieldArray name="tableData">
                  {({ push, remove }) => (
                    <tbody>
                      <tr className="header-row">
                        <td className="text-center w-10 p-0">S.No</td>
                        <td className="text-center w-10 p-0">###</td>
                        <td className="text-center w-10 p-0">{adjudicatorCount !== 9 ? "Round 1" : `Round ${round}`}</td>
                        <td className='text-center w-60 p-0'>{adjudicatorCount !== 9 ? "1 Round" : `${round} Round`}</td>
                        <td style={{ color: "#424242", padding: 0 }} className='text-center'>Action</td>
                      </tr>
                      {
                        (values.tableData.map((_, index) => (
                          <tr style={{ background: "#ffffff", height: "120px" }} key={index} >
                            <td style={{ display: "none" }}>
                              <Field name={`tableData[${index}].dancer_id`} placeholder="&nbsp;&nbsp;#" />
                            </td>
                            <td className="w-10 grey-30">
                              <div className="input-code">
                                <Field placeholder="&nbsp;&nbsp;#" value={index + 1} readOnly />
                              </div>
                            </td>
                            <NumberInputTd className='w-10 grey-30' name={`tableData[${index}].card_number`} />
                            <NumberInputTd className="w-10 grey-30" name={`tableData[${index}].score`} />
                            <CommentTd className='w-60 grey-30' name={`tableData[${index}].comments`} />
                            <DeleteIconTd onClick={() => openConfirmModal(index, values.tableData[index].card_number, values.tableData[index].dancer_id)} />
                            {showConfirmModal && <ConfirmDeleteModal modalState={true} confirm={() => handleDeletionConfirmed(remove)} cancel={handleDeletionCancelled} adjudicator={adjudicator} compId={compId} card={cardNum} competitors={competitors} results={results} />}
                          </tr>
                        )))
                      }
                      <div style={{ display: "none" }}>
                        <button type="button" ref={addNewRef} onClick={() => newRowsHandler(values, setFieldValue, push)}>New</button>
                        <button className="filled" type="submit" ref={saveRef}>Save</button>
                      </div>
                    </tbody>
                  )}
                </FieldArray>
              </table>
              {err && <div className="error-class">{err}</div>}
            </div>
            {!loading && !propLoading && competitors?.length === 0 && localStorage.getItem("resultStatus") === "create" && <div className="my-3 text-center text-danger"> There are no competitors registered for this competition</div>}
            <div className="d-flex justify-content-between">
              <div><AddButton className="plusIconbtn" onClick={addNewButtonHandler}>New Row</AddButton></div>
              <div className="d-flex align-items-center"> <button className={competitors?.length > 0 || results?.length > 0 ? "filled mb-5" : "disabled mb-5"} type="submit" >Save</button></div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default OneRoundTable