import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import "./style.css";

const CheckOutFormGrade = ({ from }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          from === "gradeReg"
            ? `${process.env.REACT_APP_BASE}/parent/grade/register/payment/success`
            : `${process.env.REACT_APP_BASE}/parent/grade/edit-register/payment/success`,
      },
    });

    if (error) {
      // Show error to customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    } else {
      // customer will be redirected to an intermediate site first to authorize the payment
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && <Loader payment />}
      {errorMessage && (
        <Alert severity="error" className="mb-4">
          {errorMessage}
        </Alert>
      )}
      <PaymentElement />
      <button className="purchaseBtn mb-5" style={{ marginTop: "40px" }} disabled={!stripe || loading}>
        Register
      </button>
    </form>
  );
};

export default CheckOutFormGrade;
