import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router';
import HeaderOrg from '../Headers/HeaderOrg';
import Tab from "@mui/material/Tab";
import { TabPanel } from '@mui/lab';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Help from '../../Components/Help/Help';
import "../CreateEventFromPrev/CreateEvent.css"
import Back from '../../Components/Back/Back';
import PreviousEvents from './PreviousEvents';

const CreateEventFromPrev = () => {
    const [value, setValue] = useState("1");
    const navigate = useNavigate()
    const { state } = useLocation()
    const eventType = state?.eventType;

    //tab change handler
    const handleChange = (event, value) => {
        setValue(value);
    };

    const backToDahboardorg = () => {
        navigate("/organizer-dashboard")
    }
    return (
        <div>
            <HeaderOrg />
            <div className="db-container">
                <div className="d-flex prev-page-title">
                    <Back onClick={backToDahboardorg} />
                    <div className="prev-evt-head ">Previous Event</div>
                </div>
                <div className="db-tab-container">
                    <TabContext sx={{ borderBottom: "1px solid Gray" }} value={value}>
                        <TabList
                            className="db-tabs-div tabs-prev  d-flex "
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab className="db-tab" label="Events" value="1" />
                            <Tab className="db-tab" label="Closed Events" value="2" />
                        </TabList>
                        <div className="hr-org-title" style={{ marginTop: "-2px" }} />

                        <TabPanel value="1">
                            <PreviousEvents eventType={eventType} eventStatus="open"/>
                        </TabPanel>
                        <TabPanel value="2">
                            <PreviousEvents eventType={eventType} eventStatus="closed"/>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
            <Help />
        </div>
    )
}

export default CreateEventFromPrev