export const formatDate = (date) => {
    let dateObject = new Date(date);
    var year = dateObject?.getUTCFullYear();
    var month = dateObject?.getUTCMonth() + 1; // Months are zero-based, so add 1
    var day = dateObject?.getUTCDate();

    // Creating a new Date object with extracted values in the local time zone
    var newDateObject = new Date(year, month - 1, day);
    return newDateObject

    // date.replace("z","") 
}