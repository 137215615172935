/*global google*/
import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, Marker, useLoadScript, } from '@react-google-maps/api';
import InfoBoxComponent from './InfoBoxComponent';

const GoogleMapComponent = (props) => {
  const mapRef = useRef(null);
  const [updatedLocDetails, setLocationDetails] = useState(null);
  const [error, setError] = useState('');
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { address, state, latitude, longitude } = props;


  useEffect(() => {
    setLocationDetails({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    });
  }, [latitude, longitude])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => setShowInfoBox(true), 200);
    }
  }, [isLoaded]);

  //to view large map
  const mapClickHandler = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(mapsUrl, '_blank');
  };

  //to redirect to direction renderer
  const redirectHandler = () => {
    const origin = '';
    const lat = parseFloat(latitude);
    const long = parseFloat(longitude);
    const redirectUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(lat + ',' + long)}`;
    window.open(redirectUrl, '_blank');
  };

  const style = {
    maxWidth: '100%',
    height: '30vh',
  };
  const containerStyle = {
    maxWidth: '100%',
    height: '30vh',
  };
  if (loadError) {
    return <p className="error">{loadError.message}</p>;
  }

  return (
    <>
      {isLoaded ? <GoogleMap
        ref={mapRef}
        style={style}
        mapContainerStyle={containerStyle}
        zoom={5}
        center={updatedLocDetails}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
          draggable: true,
          scaleControl: true,
        }}
      >
        <Marker position={updatedLocDetails} />
        {isLoaded && showInfoBox && (<InfoBoxComponent
          address={address}
          state={state}
          mapClickHandler={mapClickHandler}
          setRedirectHandler={redirectHandler}
          from={'accomodation'}
        />)}
      </GoogleMap> : (
        <p className="error">{error}</p>
      )}
    </>
  );
};

export default GoogleMapComponent;
