import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, } from '@stripe/react-stripe-js';
import './style.css';
import { Alert } from '@mui/material';
import Loader from '../../Components/Loader/Loader';

const CheckOutFormGrade = ({ from }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(true)
      return;
    }
    setLoading(true)
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: from === "gradeReg" ?
          `${process.env.REACT_APP_BASE}/parent/grade/register/payment/success` :
          `${process.env.REACT_APP_BASE}/parent/grade/edit-register/payment/success`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false)
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && <Loader payment />}
      {errorMessage && (<Alert severity="error" className="mb-4">{errorMessage}</Alert>)}
      <PaymentElement />
      <button className="purchaseBtn mb-5" style={{ marginTop: '40px' }} disabled={!stripe || loading}>Register</button>
    </form>
  );
};

export default CheckOutFormGrade;
