import React, { useEffect, useState } from 'react';
import moment from 'moment';
import HeaderOrg from '../../../Headers/HeaderOrg';
import SingleLineTitle from '../../../../Components/TitleElements/SingleLineTitle';
import { useLocation } from 'react-router-dom';
import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import PaginationNew from '../../../../Components/Pagination/PaginationNew';
import { formatAmount } from '../../../../helper/formatAmount';
import { formatDate } from '../../../../helper/formatDate';

const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transData, setTransdata] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const ManageType = localStorage.getItem('Manage');

  // get transaction events when page changes(5 events for each page)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');

  useEffect(() => {
    getAllTransaction();
  }, []);

  const getAllTransaction = async () => {
    const url =
      ManageType == 'feis'
        ? `${process.env.REACT_APP_BASE_URL}/registration/org/transactions/${eventId}`
        : `${process.env.REACT_APP_BASE_URL}/registration/org/transactions/grade/${eventId}`;
    try {
      setLoading(true);
      const response = await serviceConsumer('POST', url, { page, limit: 10 });
      let totalData = response?.totalCount;
      let totalPage = Math.ceil(Number(totalData / 10));
      setTotalPages(totalPage);
      let startindex = page % 10 === 0 ? page - 9 : Math.floor(page / 10) * 10 + 1;
      setStartIndex(startindex);
      startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9);
      let data = response?.result?.results;
      setTransdata(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    try {
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTransaction();
  }, [page]);

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div>
          <HeaderOrg />
          <SingleLineTitle titleName="Transaction History" />
          <div className="db-body pt-3 ">
            {transData?.length === 0 && !loading && (
              <p className="my-5 d-flex justify-content-center align-items-center">No Transaction history found</p>
            )}
            <center>
              {transData.map((trans) => {
                return (
                  <>
                    <div className="trans-events text-left" key={trans._id} data-value={trans._id}>
                      <div className="web-trans-list-org">
                        <img src={trans?.feisData?.logo
                          ? trans?.feisData?.logo
                          : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR70rXnB7bc15zh1PXpAo881MaQG_-fldw7KnQIsiU&s'
                        }
                          className="event-img"
                          alt="logo"
                        />
                        <div className="">
                          <p className=" m-0 trans-event-title">Register On {trans.feisData.name}</p>
                          <p className="trans-event-date mt-2  mb-0 ">
                            {trans?.feisData ? moment(formatDate(trans?.feisData?.feis_date)).format('MMM Do, YYYY') : 'NA'}
                            -{trans?.feisData?.venue_city}{trans?.feisData?.state}
                          </p>
                        </div>
                      </div>
                      <div className="event-mobile" data-value={trans._id}>
                        <div className="event-mobile-div">
                          <div>
                            <img src={trans?.feisData?.logo
                              ? trans?.feisData?.logo
                              : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR70rXnB7bc15zh1PXpAo881MaQG_-fldw7KnQIsiU&s'
                            }
                              className="event-img"
                              alt="logo"
                            />
                          </div>
                          <div>
                            <p className=" m-0 trans-event-title">Register On {trans?.feisData?.name}</p>
                            <p className="trans-event-date mt-2 mb-1">
                              {trans?.feisData ? moment(formatDate(trans?.feisData?.feis_date)).format('MMM Do, YYYY') : 'NA'}
                              -{trans?.venue_city}{trans?.state}
                            </p>
                          </div>
                        </div>
                        <div className="reg-close-trans trans-event-amt d-flex align-items-center justify-content-end">
                          <div style={{ color: '#68AD3F' }}>
                            ${trans?.userData.fname} ${trans?.userData.lname}-${trans.paidAmount && formatAmount(trans.paidAmount)}
                          </div>
                        </div>
                      </div>
                      <div className="web-btn-org mb-0">
                        {trans?.userData.fname} {trans?.userData.lname} -${trans.paidAmount && formatAmount(trans.paidAmount)}
                      </div>
                    </div>
                  </>
                );
              })}
            </center>
            <PaginationNew
              loading={loading}
              startIndex={startIndex}
              endIndex={endIndex}
              totalPages={totalPages}
              page={page}
              setPage={(val) => setPage(val)}
              setStartIndex={(val) => setStartIndex(val)}
              setEndIndex={(val) => setEndIndex(val)}
              dataPerPage={10}
            />
          </div>
          <Help />
        </div>
      )}
    </div>
  );
};

export default Transactions;
