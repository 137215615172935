import React from 'react'

function Description({ description }) {
    return (
        <>
            <p className="pageHeaders">Description</p>
            <hr className="mt-0" />
            {/* {description.split('\n').map((line, index) => (
                <div key={index} className="description">{line}</div>
            ))} */}
            <pre className="description" >{description}</pre>
        </>
    )
}

export default Description