import React, { useState, useEffect } from 'react';
import { Form, Formik, } from 'formik';
import { serviceConsumer } from '../../network/ServiceConsumer';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import MusicianModel from '../../Components/Modals/OrganizerModel/MusicianModel';
import AccomodationModel from '../../Components/Modals/OrganizerModel/AccomodationModel';
import MainDiv from '../../Components/AddedAdjdiv/MainDiv';
import Loader from '../../Components/Loader/Loader';
import AccomodationDiv from '../../Components/AddedAdjdiv/AccomodationDiv';
import { useDispatch } from 'react-redux';
import AddButton from '../../Components/AddButton/AddButton';
import AddSponserModel from '../../Components/Modals/OrganizerModel/AddSponserModel';
import { accessTillSyllabus } from '../../helper/CreateDispatches';

//all musicians mentioned represents examiners
const Collaboratorsorg = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    accessTillSyllabus(dispatch);
  }, []);

  const navigate = useNavigate();
  const [openModalMusician, setOpenModalMusician] = useState(false);
  const [openModalAccomodation, setopenModelAccomodation] = useState(false);
  const [openModalsponser, setopenModelsponser] = useState(false);
  const [adjucatorIds, setadjucatorIds] = useState([]);
  const [sponserIds, setSponsersIds] = useState([]);
  const [musicianId, setMusicianId] = useState([]);
  const [getaccomadationdata, setaccomodationdata] = useState(false);
  const [mus, setmus] = useState([]);
  const [spn, setspn] = useState([]);
  const [sponsor, setSponser] = useState([]);
  const [musician, setMusician] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [loading, setLoading] = useState();
  const [acccmodataionVal, setaccomodationVal] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [showAdjucator, setShowAdjucator] = useState(false);
  let EventID = localStorage.getItem('ID');

  const handleOpenModalMusician = () => {
    setOpenModalMusician(!openModalMusician);
  };

  const handleOpenModelAccomodation = () => {
    setopenModelAccomodation(!openModalAccomodation);
  };

  const handleOpenModelSponser = () => {
    setopenModelsponser(!openModalsponser);
  };

  const getExaminer = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}/examiners`;
      const response = await serviceConsumer('GET', url);
      setMusicianId((prev) => [...prev, response?.people?._id]);
      const musArr = response.people.map((men) => men._id);
      setMusicianId(musArr);
      setmus(response.people);
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getSponser = async () => {
    // tet
    try {
      if (!initialRender) {
        setLoading(true);
      }
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}/sponsors`;
      const response = await serviceConsumer('GET', url);
      setspn(response.people);
      const musArr = response.people.map((men) => men._id);
      setSponsersIds(musArr);
      setSponser(response.person);
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async (id, from) => {
    if (from === 'musician') {
      const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/deletegrade/${EventID}`;
      await serviceConsumer('DELETE', url);
      getExaminer();
      const newArray = musician.splice(id, 1);
      setMusician(newArray);
      setLoading(true);
    } else if (from === 'sponser') {
      const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/deletegrade/${EventID}`;
      await serviceConsumer('DELETE', url);
      getSponser();
      setLoading(true);
    } else if (from === 'acccmodataion') {
      setLoading(true);
      try {
        setLoading(true);
        if (EventID) {
          const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}/del/accomdations/${id}`;
          const response = await serviceConsumer('DELETE', url);
          setaccomodationdata(!acccmodataionVal);
          accomodationData();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const getEventDetails = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.Feis);
      }
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }

    if (musician?.length > 0) {
      try {
        setLoading(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    if (sponsor?.length > 0) {
      try {
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const accomodationData = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/accomdations/${EventID}`;
        const response = await serviceConsumer('GET', url);
        setaccomodationVal(response.Accomdations || []);
      }
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      if (!initialRender) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setInitialRender(false);
    setLoading(true);
    Promise.all([
      getExaminer(),
      getSponser(),
      getEventDetails(),
      accomodationData(),
    ])
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
  }, []);


  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => {
        getExaminer();
      }, 300);
    }
  }, [musician]);

  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => {
        getSponser();
      }, 300);
    }
  }, [sponsor]);

  useEffect(() => {
    if (!initialRender) {
      getEventDetails();
    }
  }, [musician, sponsor, getaccomadationdata]);

  // submitHandler
  const finishHandler = async () => {
    let val = {
      adjudicators: adjucatorIds.filter((item) => item != null),
      sponsors: sponserIds.filter((item) => item != null),
      examiners: musicianId.filter((item) => item != null),
      page: 7,
    };
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer('PUT', url, val);
      if (response.success === true) {
        localStorage.setItem('page', 7);
        localStorage.removeItem('previousEvent');
        navigate('/organiser-dasboard/createevent-grade/finalGradeDetails');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const collaboratorInitialValues = {
  };
  return (
    <div className="AboutOrg">
      <div>
        {loading && <Loader />}
        <Formik
          initialValues={collaboratorInitialValues}
        >
          {(formik) => (
            <Form
              className="form form-label-right"
              autoComplete="off"
            >
              <div className="syllabusMain">
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Complete collaborators</h4>
                    <p className="eventDescription">
                      Examiners, Sponsors, And others.
                    </p>
                  </div>
                  <label className="eventGradeColabLabel ">Examiners:</label>
                  <br />
                  {mus?.length > 0 &&
                    mus.map((musician, index) => {
                      return (
                        <>
                          <MainDiv
                            name={musician?.name}
                            location={musician?.location}
                            index={index}
                            photo={musician?.photo}
                            setMusician={setMusician}
                            onDelete={onDelete}
                            id={musician._id}
                            from={'musician'}
                          />
                        </>
                      );
                    })}
                  <AddButton className="plusIconbtn" onClick={handleOpenModalMusician} from="collab">
                    Add
                  </AddButton>
                  <br />
                  <label className="eventGradeColabLabel mt-3">
                    Accommodations:
                  </label>
                  <br />
                  {acccmodataionVal?.length > 0 &&
                    acccmodataionVal?.map((accomodation, index) => {
                      return (
                        <>
                          <AccomodationDiv
                            name={accomodation?.name}
                            location={accomodation?.location}
                            index={index}
                            photo={accomodation?.photo}
                            city={accomodation?.city}
                            country={accomodation?.country}
                            state={accomodation?.state}
                            setSponser={setSponser}
                            from={'acccmodataion'}
                            onDelete={onDelete}
                            id={accomodation._id}
                          />
                        </>
                      );
                    })}
                  {acccmodataionVal?.length < 5 && (
                    <AddButton className="plusIconbtn" onClick={handleOpenModelAccomodation} from="collab"> Add</AddButton>
                  )}
                  <br />
                  <label className="eventGradeColabLabel mt-3">
                    Sponsors:{' '}
                  </label>
                  <br />
                  {spn?.length > 0 &&
                    spn?.map((sponser, index) => {
                      return (
                        <>
                          <MainDiv
                            name={sponser?.name}
                            location={sponser?.location}
                            index={index}
                            photo={sponser?.photo}
                            sponsor={sponsor}
                            setSponser={setSponser}
                            from={'sponser'}
                            id={sponser._id}
                            onDelete={onDelete}
                          />
                        </>
                      );
                    })}
                  <AddButton className="plusIconbtn" onClick={handleOpenModelSponser} from="collab">Add</AddButton>
                  <div className="OrgBackSubBtns">
                    <button onClick={finishHandler} className="filled mt-4 mb-5" >Finish</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {openModalMusician && (
        <MusicianModel
          modalState={openModalMusician}
          setOpenModalMusician={setOpenModalMusician}
          action={handleOpenModalMusician}
          showAdjucator={showAdjucator}
          getMusician={getExaminer}
          mus={mus}
          musician={musician}
          setMusician={setMusician}
          from={'grade'}
        />
      )}
      {openModalAccomodation && (
        <AccomodationModel
          modalState={openModalAccomodation}
          action={handleOpenModelAccomodation}
          showAdjucator={showAdjucator}
          setaccomodationdata={setaccomodationdata}
          accomodationData={accomodationData}
          from={'grade'}
        />
      )}
      {openModalsponser && (
        <AddSponserModel
          action={handleOpenModelSponser}
          modalState={handleOpenModelSponser}
          showAdjucator={showAdjucator}
          setopenModelsponser={setopenModelsponser}
          sponsor={sponsor}
          getSponser={getSponser}
          from="grade"
        />
      )}
    </div>
  );
};

export default Collaboratorsorg;
