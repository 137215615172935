import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './Homepage.css';
import Header from './Header';
import { Footer } from './Footer';
import Help from '../Assets/Help.svg';
import HomePageTitle from './HomePageTitle';
import TabsSection from './TabsSection';
import DevinsMessage from './DevinsMessage';
import MessageForParents from './MessageForParents';
import MessageForOrganizers from './MessageForOrganizers';
// import NewBrandInfo from '../Components/NewBrandInfo/NewBrandInfo';

const Homepage = () => {
  const [isToastVisible, setIsToastVisible] = useState(true);
  const [footerLogoClicked, setFooterLogoClicked] = useState(false);
  const { state } = useLocation();
  const { authReducer } = useSelector((state) => state);
  const autherizer = localStorage.getItem('unauthorized');

  useEffect(() => {
    let sessionalert = state?.from;
    if (sessionalert && !autherizer) {
      localStorage.setItem('unauthorized', true);
      if (!localStorage.getItem('toastShown') && sessionalert !== '404PAGE') {
        toast.error(sessionalert, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          onClose: () => {
            localStorage.setItem('toastShown', 'true');
          },
        });
        setIsToastVisible(false);
      }
    }
    if (sessionalert === '404PAGE') {
      toast.error('Please Log In', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        onClose: () => {
          localStorage.setItem('toastShown', 'true');
        },
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (footerLogoClicked) {
      window.location.reload();
      setTimeout(() => { setFooterLogoClicked(false); }, 1000);
    }
    window.scrollTo(0, 0);
    document.title = 'Feis.Link :: Home';
  }, []);



  return (
    <>
      {/* <NewBrandInfo /> */}
      <Header />
      <Container>
        <HomePageTitle />
        <TabsSection />
        <DevinsMessage />
        <MessageForParents />
        <MessageForOrganizers />
      </Container>
      <div style={{ position: 'relative' }}>
        <div className="d-flex justify-content-end m-5">
          <a id="mailIcon" href="mailto:info@feis.link"><img src={Help} alt="help" /></a>
        </div>
      </div>
      <Footer setFooterLogoClicked={(val) => setFooterLogoClicked(val)} />
      <ToastContainer />
    </>
  );
};

export default Homepage;
