// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }
  
  .toggle-switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EAEAEA;
    transition: 0.4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 3px;
    background-color: #797979;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input[type="checkbox"]:checked + .slider:before {
    transform: translateX(33px);
    background-color: green;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/ToggleSwitch/ToggleSwitch.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,UAAU;IACV,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,2BAA2B;IAC3B,uBAAuB;EACzB","sourcesContent":[".toggle-switch {\n    position: relative;\n    display: inline-block;\n    width: 65px;\n    height: 30px;\n  }\n  \n  .toggle-switch input[type=\"checkbox\"] {\n    opacity: 0;\n    width: 0;\n    height: 0;\n  }\n  \n  .slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #EAEAEA;\n    transition: 0.4s;\n    border-radius: 24px;\n  }\n  \n  .slider:before {\n    position: absolute;\n    content: \"\";\n    height: 23px;\n    width: 23px;\n    left: 4px;\n    bottom: 3px;\n    background-color: #797979;\n    transition: 0.4s;\n    border-radius: 50%;\n  }\n  \n  input[type=\"checkbox\"]:checked + .slider:before {\n    transform: translateX(33px);\n    background-color: green;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
