// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchaseBtn:hover{
background-color: #FBB040;
}`, "",{"version":3,"sources":["webpack://./src/Parent/Payments/style.css"],"names":[],"mappings":"AAAA;AACA,yBAAyB;AACzB","sourcesContent":[".purchaseBtn:hover{\nbackground-color: #FBB040;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
