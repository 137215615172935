import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg";
import Button from '../../../../Button/Button';
import { openOrCloseModal } from '../../../../../helper/CreateDispatches';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import Loader from '../../../../Loader/Loader';

const AddAStageModal = ({ modalState, stageName, addStage, eventId, tableData }) => {
    const [name, setName] = useState("");
    const [touched, setTouched] = useState(false);
    const [loading, setLoading] = useState(false)
    const stageCount = Object.keys(tableData)?.length
    const dispatch = useDispatch();
    const error = touched && (name === "")
    const isValid = touched && (name !== "")

    const modalCloseHandler = () => {
        openOrCloseModal(dispatch, "", "");
    }

    const handleInputChange = (e) => {
        setName(e.target.value);
        setTouched(true); // Set touched to true when input changes
    }

    const addStageHandler = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/create-stage`
        const payload = {
            eventId: eventId,
            data: [{ stage: stageCount, stageName: name }],
        }
        setLoading(true)
        const response = await serviceConsumer("POST", url, payload)
        addStage(`${response?.result[0]?.stageName}_${response?.result[0]?._id}`)
        setLoading(false)
        modalCloseHandler()
    }

    return (
        <>
            {loading && <Loader />}
            <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
                <div className="org-modal-div " style={{ width: "350px" }}>
                    <div className="close-div mb-1">
                        <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="split-form-div">
                            <p className="modal-org-title text-center my-2">Add a Stage</p>
                            <div className="d-flex flex-column align-items-center ">
                                <div className="d-flex flex-column">
                                    <label className="label-head">{`stage ${stageCount}:`}</label>
                                    <input
                                        name="stage"
                                        type="text"
                                        className="modal-input"
                                        value={name}
                                        onChange={handleInputChange}
                                        onBlur={() => setTouched(true)}
                                    />
                                    {error && <div className='auth-error'>Stage name is required!</div>}
                                </div>
                                <div className="d-flex flex-grow-1 justify-content-center mt-4   mb-5">
                                    <Button type="button" onClick={addStageHandler} className={isValid ? "filled" : "disabled"}> Add Stage</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    );
}

export default AddAStageModal;
