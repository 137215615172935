import React, { useState, useEffect } from 'react'
import HeaderOrg from '../Headers/HeaderOrg'
import SingleLineTitle from '../../Components/TitleElements/SingleLineTitle'
import { serviceConsumer } from '../../network/ServiceConsumer'
import { Field, Form, ErrorMessage, Formik } from 'formik'
import * as Yup from "yup";
import Button from '../../Components/Button/Button'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LOGOUT } from '../../redux/actionTypes/authActionType'
import { Alert } from "@mui/material";
import Loader from '../../Components/Loader/Loader'
import Help from '../../Components/Help/Help'
import { ResetLocalStoragePagination } from '../../helper/setLocalStorage'
import { passwordValidation, repeatPwdValidation } from '../../helper/Validations'

const AccountOrg = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, showSuccess] = useState("")
    const [error, showError] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //get Email to email input
    const getEmail = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/user`;
        try {
            setLoading(true)
            const response = await serviceConsumer("GET", url);
            setEmail(response.email)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        getEmail();
        ResetLocalStoragePagination()
    }, []);

    //formik initial values
    const changePasswordInitialValues = {
        password: "",
        repeatPassword: "",
    };
    //formik validation schema
    const changePasswordValidationSchema = Yup.object().shape({
        password: passwordValidation,
        repeatPassword: repeatPwdValidation,
    });

    //formik submit handler
    const changePasswordHandler = async (values) => {
        let payload = { password: values.password };
        const url = `${process.env.REACT_APP_BASE_URL}/renew-pw`;
        try {
            setLoading(true)
            const response = await serviceConsumer("POST", url, payload)
            if (response.success) {
                localStorage.clear();
                showSuccess(response.message);
                setTimeout(() => {
                    dispatch({
                        type: LOGOUT,
                    });
                }, 3000);
                setTimeout(() => navigate("/login-org"), 1000)
            }
        }
        catch (error) {
            showError(error.messages);
            setTimeout(() => showError(""), 3000);
        }
        setLoading(false)
    }

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <SingleLineTitle titleName="My account" />
            <div className="org-account-outer">
                <div className="org-account-inner">
                    <div className="org-body-title">Profile</div>
                    <div className="account-content">Username, Photo and others.</div>
                    <div className="mt-1 mb-3 hr-org-body" />
                    <div className="label-head  ">Email Address:</div>
                    <input className="input-box" style={{ color: "#C9C9C9" }} value={email} readOnly />
                    <div className="org-body-title" style={{ marginTop: "50px" }}>Password</div>
                    <div className="account-content">Change your password when you need it. For security, we recommend that it be made up of letters and numbers.</div>
                    <div className="mt-1 mb-2 hr-org-body" />
                    {success && <Alert sx={{ color: "#68AD3F" }} icon={false} severity="success">{success}</Alert>}
                    {error && <Alert style={{ color: "error" }} icon={false} severity="error">{error}</Alert>}

                    <Formik
                        initialValues={changePasswordInitialValues}
                        validationSchema={changePasswordValidationSchema}
                        onSubmit={changePasswordHandler}
                    >
                        {({ dirty, isValid }) => {
                            return (
                                <Form autoComplete="off">
                                    <div >
                                        <div className="d-flex flex-column ">
                                            <label className="label-head">New Password:</label>
                                            <Field className="input-box" type="password" name="password" placeholder="New Password" />
                                            <ErrorMessage className="auth-error" name="password" component="div" />
                                        </div>
                                        <div className="d-flex flex-column ">
                                            <label className="label-head">Repeat New Password: </label>
                                            <Field className="input-box" type="password" name="repeatPassword" placeholder="Repeat New Password" />
                                            <ErrorMessage className="auth-error" name="repeatPassword" component="div" />
                                        </div>
                                        <div className=" mt-1" style={{ marginBottom: "175px" }}>
                                            <Button type="submit" className={isValid && dirty ? "filled" : "disabled"} disabled={!isValid && !dirty}>Change password</Button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    <Help />
                </div>
            </div>
        </>
    )
}

export default AccountOrg