import React from 'react'
import { Box } from "@mui/material";

function DancersWithResult({ dancerdetails }) {
    return (
        <>
            <div className="pageHeaders ">Registered dancers</div>
            <hr className="mt-0" />
            <div className="d-flex flex-wrap justify-content-between">
                {dancerdetails?.map((dancerdata) => (
                    <div className="adjudicator">
                        <Box
                            className="adjudicatorCard"
                            sx={{ boxShadow: 1 }}
                            style={{
                                width: "100%",
                                minHeight: "63px",
                                background: "#FFFFFF",
                                borderRadius: "4px",
                                boxShadow: "none",
                                padding: "5px 10px",
                                margin: '12px 0px',
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <div className="adjudicatorDescription">
                                    <h6 style={{ marginBottom: "2px" }}>{dancerdata.result.fname} {dancerdata.result.lname}</h6>
                                    <p
                                        style={{
                                            color: "#68AD3F",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            color: "#000000",
                                            opacity: "0.2",
                                        }}
                                    >
                                        {dancerdata.result.levels}
                                    </p>
                                </div>
                            </div>
                        </Box>
                    </div>
                ))}
            </div>
        </>
    )
}

export default DancersWithResult