import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentHeader from "./ParentHeader/ParentHeader";
import Loader from "../Components/Loader/Loader";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import doubleArrow from "../Assets/doubleArrow.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { serviceConsumer } from "../network/ServiceConsumer";
import "../Components/Table.css";
import { compTableSearch } from "../helper/CompTableSearch";
import SearchInput from "../Components/SearchInput";
import { groupAndSortComp, sortCompetitions } from "../helper/SortSyllabus";
import { formatDate } from "../helper/formatDate";

const Competitors = () => {
  const [searchInput, setSearchInput] = useState("")
  const [responseArray, setResponseArray] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [tableArr, setTableArr] = useState([])
  const [sorterData, setSorterData] = useState("")
  const [sortOrder, setSortOrder] = useState("asc")
  const [eventProp, setEventProp] = useState()

  const navigate = useNavigate();
  const { state, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const eventId = searchParams.get('id');

  let event = state?.from;

  useEffect(() => {
    getAllDetails();
  }, [])

  // search input in competitors
  useEffect(() => {
    compTableSearch(responseArray, searchInput, setTableArr)
  }, [responseArray, searchInput]);


  //button handlers
  const backToViewEvent = () => {
    window.history.go(-1)
  };

  const goToRegisgternow = () => {
    if (localStorage.getItem("accessToken")) {
      navigate(`/register-dancers?id=${eventProp._id}`, { state: { event: eventProp }, });
    } else {
      navigate("/login")
    }
  };

  //get event details for title and competition details for table
  const getAllDetails = async () => {
    try {
      setLoading(true);
      const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`;
      const eventResponse = await serviceConsumer('GET', eventUrl);
      setEventProp(eventResponse.Feis);
      document.title = `Feis.Link - ${eventResponse?.Feis?.name} - Competitors`
      const competitionUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/dancers`;
      const competitionResponse = await serviceConsumer('GET', competitionUrl);
      setResponseArray(competitionResponse?.result);
      setLoading(false);
    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setLoading(false);
    }
  };

  const sortCompetitorsHandler = (sorter) => {
    let order = sorter === sorterData ? (sortOrder === "asc" ? "desc" : "asc") : "asc";
    order === "asc" && sorter === "id" && tableArr.sort((a, b) => a.card_number - (b.card_number))
    order === "asc" && sorter === "fname" && tableArr.sort((a, b) => a.dancerData.fname.localeCompare(b.fname))
    order === "asc" && sorter === "lname" && tableArr.sort((a, b) => a.dancerData.lname.localeCompare(b.lname))
    order === "asc" && sorter === "school" && tableArr.sort((a, b) => a.dancerData.school.localeCompare(b.school))
    order === 'asc' && sorter === 'competitor' && tableArr.sort((a, b) => a.competitonData[0].code.localeCompare(b.competitonData[0].code));

    order === "desc" && sorter === "id" && tableArr.sort((a, b) => b.card_number - (a.card_number))
    order === "desc" && sorter === "fname" && tableArr.sort((a, b) => b.dancerData.fname.localeCompare(a.fname));
    order === "desc" && sorter === "lname" && tableArr.sort((a, b) => b.dancerData.lname.localeCompare(a.lname));
    order === "desc" && sorter === "school" && tableArr.sort((a, b) => b.dancerData.school.localeCompare(a.school));
    order === 'desc' && sorter === 'competitor' && tableArr.sort((a, b) => b.competitonData[0].code.localeCompare(a.competitonData[0].code));

    setTableArr(tableArr)
    setSorterData(sorter)
    setSortOrder(order)
  }
  return (
    <>
      {loading && <Loader />}
      <ParentHeader />
      {!loading && <div className="d-flex justify-content-center  view-header">
        <div className="d-flex  flex-grow-1 ">
          <div className="flex-grow-1">
            <div
              className="d-flex align-items-center"
              onClick={backToViewEvent}
              style={{ color: "#68AD3F", cursor: "pointer", width: " min-content" }}
            >
              <div><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
              <div style={{ fontWeight: "500", fontSize: "16px", marginTop: "2px" }}>Back</div>
            </div>
            <div className="d-flex header-mobile ">
              <div className="d-flex flex-grow-1 align-items-center">
                <img src={eventProp?.logo} className="event-img-view" alt="logo" />
                <div className="registerevent-details d-flex flex-grow-1 align-items-center">
                  <div>
                    <p className="title m-1">{eventProp?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
                    <p className="event-name m-1">{eventProp?.name || "NA"}</p>
                    <p className="reg-date-open m-1">
                      {(eventProp?.eventType === "Feiseanna" ? eventProp?.feis_date : eventProp?.exam_date)
                        ? moment(formatDate(eventProp?.eventType === "Feiseanna" ? eventProp?.feis_date : eventProp?.exam_date)).format(" MMMM Do, YYYY")
                        : "NA"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3 mb-md-0">
                <div className="d-flex align-items-center">
                  <div className="reg-details">
                    <p className="reg-date ">{(event === "open") || (event === "edit") ? "Registration closes:" : "Registration closed:"}</p>
                    <p className="reg-date-close m-0">
                      {eventProp?.closing_date ? moment(formatDate(eventProp?.closing_date)).format("MMMM Do,yyyy") : "NA"}
                    </p>
                  </div>
                </div>
                <div className="registerbutton d-flex align-items-center">
                  <div>
                    {event === "open" && <button className="filled" onClick={goToRegisgternow} > Register now </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {!loading &&
        <div className="view-body">
          <div>
            <div>
              <div className="d-flex align-items-center mt-4">
                <div style={{ fontSize: "22px", fontWeight: "500", color: "#424242" }}> Competitors </div>
                <div> <div className="tableCount mx-2">{tableArr?.length}</div> </div>
              </div>
              <hr className="m-0" />
              <div className="mt-4 mx-1" style={{ fontSize: "14px", fontWeight: "500", }}>Search :</div>
            </div>
            <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
            <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px" }}>
              <table className="table my_table competitorTable ">
                <tbody>
                  <tr style={{ fontWeight: "500" }} className="header-row">
                    <td className="text-center w-10 mx-5">#{" "}
                      <span>
                        <img src={doubleArrow} onClick={() => sortCompetitorsHandler("id")} alt="" style={{ cursor: "pointer" }} />
                      </span></td>
                    <td className="mx-5 w-10">First name{" "}<span>
                      <img src={doubleArrow} onClick={() => sortCompetitorsHandler("fname")} alt="" style={{ cursor: "pointer" }} />
                    </span></td>
                    <td className="w-20 p-25">Last name  {" "}<span>
                      <img src={doubleArrow} onClick={() => sortCompetitorsHandler("lname")} alt="" style={{ cursor: "pointer" }} />
                    </span></td>
                    <td className="w-25 p-25">School  {" "}<span>
                      <img src={doubleArrow} onClick={() => sortCompetitorsHandler("school")} alt="" style={{ cursor: "pointer" }} />
                    </span></td>
                    <td className="w-25 p-25">Competitions  {" "}<span>
                      <img src={doubleArrow} onClick={() => sortCompetitorsHandler("competitor")} alt="" style={{ cursor: "pointer" }} />
                    </span></td>
                  </tr>
                  {tableArr.map((dancer) => {
                    return <tr>
                      <td className="text-center mx-5 grey-30 w-10">{dancer?.card_number}</td>
                      <td className="mx-5 grey-30 w-20" >{dancer?.dancerData?.fname}</td>
                      <td className="grey-30 w-20">{dancer?.dancerData?.lname}</td>
                      <td className="grey-30 w-25">{dancer?.dancerData?.school}</td>
                      <td className="grey-30 w-25">{dancer?.competitonData && groupAndSortComp(dancer.competitonData).map((item, index) => { return (dancer?.competitonData?.length === index + 1) ? item?.code : `${item?.code}, ` }
                      )}</td>
                    </tr>
                  })}
                </tbody>
              </table>
              {!loading && responseArray.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>}
              {tableArr.length === 0 && searchInput !== "" && responseArray.length > 0 && <div className="d-flex justify-content-center my-5">There are no competitors matching your search</div>}
              {error && <div className="text-center " style={{ color: "red" }}>{error}</div>}
            </div>
          </div>
        </div>}
    </>
  );
};

export default Competitors;
