import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentHeader from "../ParentHeader/ParentHeader";
import Loader from "../../Components/Loader/Loader";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import favIcon from "../../Assets/favicon.ico";
import Description from "../Components/Description";
import LocationDetails from "../Components/LocationDetails";
import Help from "../../Components/Help/Help";
import AccommodationsList from "../Components/AccommodationsList";
import { serviceConsumer } from "../../network/ServiceConsumer";
import CollaboratorsList from "../Components/CollaboratorsList";
import GradeTable from "../Components/GradeTable";
import { formatDate } from "../../helper/formatDate";
import { sortGrades } from "../../helper/SortSyllabus";

const GradeViewEvent = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const eventId = searchParams.get('id')
  const [collaborators, setCollaborators] = useState()
  const [eventProp, setEventProp] = useState()
  const [urlEventName, setUrlEventName] = useState()
  const [grades, setGrades] = useState([])

  //getting details from passed events
  let event = state?.from;

  const getInitialDetails = async () => {
    try {
      setLoading(true);
      const eventUrl = localStorage.getItem("accessToken") ? `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}` : `${process.env.REACT_APP_BASE_URL}/grade-exam/single/${eventId}`
      const eventResponse = await serviceConsumer('GET', eventUrl)
      const eventNameForUrl = eventResponse?.grade_exams?.name?.split(" ").join("+");
      setUrlEventName(eventNameForUrl)
      setEventProp(eventResponse.grade_exams)
      document.title = `Feis.Link - ${eventResponse?.grade_exams?.name}`;
      const collaboratorsUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/collaborators/${eventId}`
      const collaboratorsResponse = await serviceConsumer('GET', collaboratorsUrl)
      setCollaborators(collaboratorsResponse.result)
      setGrades(sortGrades(collaboratorsResponse.result.grades))
      setLoading(false)
    } catch (error) {
      navigate("pageNotFound", { replace: true })
      setLoading(false)
    }
  };

  // if there isd no data it will navigate to ther home page
  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialDetails()
  }, []);

  //button handlers
  const backToViewEvent = () => {
    window.history.go(-1);
  };
  const goToRegisgternow = () => {
    if (localStorage.getItem("accessToken")) {
      navigate(`/register-grade-exam?id=${eventProp?._id}`, {
        state: { event: eventProp },
      });
    } else {
      localStorage.setItem("path", `/grade/${eventProp?.name}?id=${eventId}`);
      localStorage.setItem("eventId", eventProp._id);
      localStorage.setItem("from", "open");
      localStorage.setItem("eventType", eventProp?.eventType);
      navigate("/login");
    }
  };

  const viewSyllabus = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const printScheduleHandler = () => {
    window.open(eventProp.schedulePdfUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <ParentHeader />
      {loading && <Loader />}
      {!loading && <>
        <div className="d-flex justify-content-center  view-header">
          <div className="d-flex  flex-grow-1 align-items-center">
            <div className="flex-grow-1">
              <div className="d-flex align-items-center" style={{ color: "#68AD3F", cursor: "pointer", width: " min-content", }} >
                <div onClick={backToViewEvent}><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
                <div onClick={backToViewEvent} className="back">Back</div>
              </div>
              <div className="d-flex header-mobile">
                <div className="d-flex flex-grow-1 align-items-center">
                  <img src={eventProp?.logo || favIcon} className="event-img-view" alt="logo" />
                  <div className="registerevent-details d-flex flex-grow-1 align-items-center">
                    <div>
                      <p className="title m-1">{eventProp?.eventType}{event === "closed" && "-Closed"}{event === "upcoming" && "-Upcoming"}</p>
                      <p className="event-name m-1">{eventProp?.name || "NA"}</p>
                      <p className="reg-date-open m-1">{moment(formatDate(eventProp?.exam_date)).format(" MMMM Do, YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <div className="reg-details">
                      <p className="reg-date ">Registration {event === "closed" ? "closed:" : "closes:"}</p>
                      <p className="reg-date-close m-0">{moment(formatDate(eventProp?.closing_date)).format(" MMMM Do, YYYY")}</p>
                    </div>
                  </div>
                  <div className="registerbutton d-flex align-items-center">
                    <div>
                      {event === "open" && <button onClick={goToRegisgternow} className="filled">Register Now</button>}
                      {event === "upcoming" && <button onClick={goToRegisgternow} className="disabled">Register Now</button>}
                      {event === "closed" && <button className="disabled">View Results</button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="view-body">
          <div className="parent-register row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 leftBlock">
              <Description description={eventProp?.description} />
              <div className=" d-flex gap-2">
                {eventProp?.syllabus_file && <button className="outlined" onClick={() => viewSyllabus(eventProp?.syllabus_file)} > Syllabus</button>}
                {eventProp?.schedulePdfUrl && <button className="outlined" onClick={printScheduleHandler}>Schedule </button>}
              </div>
              <CollaboratorsList collaborators={collaborators} />
              <GradeTable grades={grades} />
            </div>
            <br />
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-9" >
              <LocationDetails event={eventProp} />
              <br />
              <AccommodationsList accomodations={eventProp?.accomodations} />
              <Help />
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};

export default GradeViewEvent;
