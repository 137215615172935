import React from 'react'
import InfiniteScrollComponent from '../../Components/InfiniteScrollComponent';
import WebCard from '../../Components/Card/WebCard';
import MobileCard from '../../Components/Card/MobileCard';

const DashboardCards = ({ events, getAllEvents, hasMore, from }) => {
    return (
        <InfiniteScrollComponent events={events} getAllEvents={getAllEvents} hasMore={hasMore} >
            {events.map((event) => {
                return (
                    <div className="register-events" key={event._id} data-value={event._id} >
                        <WebCard event={event} from={from} isHomePage={false} />
                        <MobileCard event={event} from={from} isHomePage={false} />
                    </div>

                );
            })}
        </InfiniteScrollComponent>
    )
}

export default DashboardCards