// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nine-judge-table{
    width:2000px;
}

tr.increasedBorderBottom{
    border-bottom-color: black;
}`, "",{"version":3,"sources":["webpack://./src/Organizer/ManageFeisGrade/RedirectFeis/Tabulation/ResultTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".nine-judge-table{\n    width:2000px;\n}\n\ntr.increasedBorderBottom{\n    border-bottom-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
