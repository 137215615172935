import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoggedIn = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.getItem("organizer") ? navigate("/organizer-dashboard") : navigate("/parent");
  }, []);

  return null;
};

export default LoggedIn;
