import React from 'react'
import GoogleMapComponent from '../../Components/Maps/GoogleMapComponent'

const LocationDetails = ({ event }) => {
    return (
        <>
            <p className="pageHeaders">Event Location</p>
            <hr className="mt-0" />
            <GoogleMapComponent latitude={event?.latitude} longitude={event?.longitude} address={event?.venue} state={event?.state} />
            <br></br>
            <div className="location-Details">
                <p className="m-0">Venue: {event?.venue_name}</p>
                <p className="m-0">City: {event?.venue_city}</p>
                <p className="m-0">Region: {event?.region}</p>
                <p className="m-0">Postal Code: {event?.postal_code}</p>
            </div>
        </>
    )
}

export default LocationDetails