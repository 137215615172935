import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import "./style.css";

const CheckoutForm = ({ from }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          from === "fiesReg"
            ? `${process.env.REACT_APP_BASE}/parent/feis/register/payment/success`
            : `${process.env.REACT_APP_BASE}/parent/feis/edit-register/payment/success`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // your customer will be redirected to an intermediate site first to authorize the payment
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && <Loader payment />}
      {errorMessage && (
        <Alert severity="error" className="mb-4">
          {errorMessage}
        </Alert>
      )}
      <PaymentElement />
      <button className="purchaseBtn mb-5" style={{ marginTop: "40px" }} disabled={!stripe || loading}>
        Register
      </button>
    </form>
  );
};

export default CheckoutForm;
