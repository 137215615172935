import React, { useState } from 'react'
import photoPlaceholder from "../../Assets/photoPlaceholder.png"
import AcommodationModal from '../../Components/Modals/AcommodationModal/AcommodationModal';

const AccommodationsList = ({ accomodations }) => {
    const [open, setOpen] = React.useState(false);
    const [accomodation, setAccomodation] = useState([])

    const handleOpen = (mappedAcc) => {
        setAccomodation(mappedAcc);
        setOpen(true);
    }

    return (
        <>
            {accomodations?.length > 0 && <><p className="pageHeaders">Accommodation</p>
                <hr className="mt-0" /></>}
            {accomodations?.map((accommodation, index) => {
                const statePosition = accommodation?.address.indexOf(accommodation?.postal_code);
                const shortenedAddress = statePosition !== -1 ? accommodation?.address.substring(0, statePosition).trim() : accommodation?.address
                return (
                    <div key={index} style={{ cursor: "pointer", borderRadius: "10px", }} >
                        <div onClick={() => handleOpen(accommodation)}>
                            <div className="AccomodationHeader mb-2 d-flex w-100" style={{ height: "90px" }}>
                                <img src={accommodation.photo ? accommodation?.photo : photoPlaceholder} alt="logo"
                                    style={{ height: "90px", width: "100px", objectFit: "fill", borderRadius: "6px 0px 0px 6px", }}
                                />
                                <div className="d-flex align-items-center w-75" style={{ paddingLeft: "10px" }}>
                                    <div className="d-flex flex-column justify-content-center" key={index}>
                                        <div style={{ color: "#424242" }}>
                                            {accommodation.name.length > 25 ? accommodation.name.substring(0, 25).trim() : accommodation.name}
                                            {accommodation.name.length > 25 ? <span id="showAccomodation">...</span> : ""}
                                        </div>
                                        <div style={{ color: "#68AD3F", fontSize: "14px" }}>
                                            {shortenedAddress}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
            })}
            <AcommodationModal accomodation={accomodation} setOpen={setOpen} open={open} />
        </>
    )
}

export default AccommodationsList