import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { FilledButton, AddButton } from './Components/Buttons';
import SyllabusTable from './Components/SyllabusTable';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { accessTillAbout, accessTillSyllabus } from '../../helper/CreateDispatches';
import { sortLevelData } from '../../helper/SortSyllabus';

const SyllabusFies = (props) => {
  const [showNewLevel, setShowNewLevel] = useState(false);
  const [newLevelName, setNewLevelName] = useState('');
  const [loading, setLoading] = useState(false);
  const [allLevelData, setAllLevelData] = useState()
  const [eventDetails, setEventDetails] = useState()
  const dispatch = useDispatch();
  let EventID = localStorage.getItem('ID');
  const page = localStorage.getItem('page');
  const TAB = localStorage.getItem('TAB');

  const getEventDetails = async () => {
    try {
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
        const response = await serviceConsumer('GET', url);
        if (response.success === true) {
          setEventDetails(response?.Feis);
          page > TAB && localStorage.setItem('page', response.Feis?.page);
        }
      }
    } catch (error) {
    }
  };

  const getSyllabusDetails = async () => {
    try {
      setLoading(true)
      const url = `${process.env.REACT_APP_BASE_URL}/feis/syllabus/${EventID}`;
      const response = await serviceConsumer('GET', url);
      const sortedLevelData = await sortLevelData(response?.result);
      setAllLevelData(sortedLevelData);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    getSyllabusDetails()
    getEventDetails();
    accessTillSyllabus(dispatch)
  }, [])

  const newLevelHandler = () => {
    let allLevelDataCopy = [...allLevelData]
    allLevelDataCopy.push({ _id: newLevelName, competitions: [] })
    setAllLevelData(allLevelDataCopy)
    setShowNewLevel(false);
  };

  // newlevel
  const renderNewLevelInput = () => (
    <div className="d-flex">
      <div>
        <input
          name="newLevel"
          type="text"
          className="newLevelField"
          value={newLevelName}
          onChange={(e) => { setNewLevelName(e.target.value); }}
        />
        <FilledButton onClick={() => newLevelHandler()} disabled={!newLevelName}>Add Level</FilledButton>
      </div>
    </div>
  );

  const renderNewLevelButtonSection = (showNewLevel) => (
    <div>
      <AddButton onClick={() => {
        setShowNewLevel(true);
        setNewLevelName('');
      }}
        disabled={showNewLevel}
      >Add Level</AddButton>
    </div>
  );


  const nextHandler = async () => {
    let value = { ...eventDetails, page: localStorage.getItem('page') > 5 ? localStorage.getItem('page') : 5 };
    delete value.competitions;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
      const response = await serviceConsumer('PUT', url, value);
      props.setValue('6');
      localStorage.setItem('TAB', 6);
      if (Number(localStorage.getItem('page')) < 5) {
        localStorage.setItem('page', 5);
      }
      accessTillAbout(dispatch)
      setLoading(false);

    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <div>
        <Header />
        {allLevelData?.map((levelDetail, index) => { return <SyllabusTable levelDetailProp={levelDetail} feisId={EventID} getSyllabusDetails={getSyllabusDetails} /> })}
        {showNewLevel && renderNewLevelInput()}
        {renderNewLevelButtonSection(showNewLevel)}
        <div className="mb-5">
          <button className='filled' onClick={nextHandler} >  Next </button>
        </div>
      </div>
    </>
  );
};

export default SyllabusFies;

const Header = () => {
  return (
    <div className="detailsHeaders">
      <h4 className="eventPage">Complete syllabus</h4>
      <p className="eventDescription">Complete Syllabus with categories</p>
    </div>
  );
};

