// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-ft-btn {
  font-size: 16px;
 font-weight: 500;
 padding: 0px 11px;
 background-color: transparent;
 border-radius: 4px;
 color: #68AD3F;
 border: 2px solid #68AD3F !important;
 width: 80px;
 height: 42px;
}
.login-ft-btn::hover{
  border: 2px solid #68AD3F !important;
}
.signup-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 11px;
  background-color: #68AD3F;
  border-radius: 4px;
  color: white;
  border: none;
  width: 80px;
  height: 42px;
}
.create-event-col-1{
  color: var(--neutral-text, #424242);
  font-size: 14px;
  display:flex;
justify-content:end;
}

.create-event-col-2{
  color: #FBB040;
font-size: 14px;
font-weight: 500;
display:flex;
justify-content:end;
cursor:pointer;
}
.create-event-col-3{
  color:#68AD3F;
  font-size: 14px;
font-weight: 500;
cursor:pointer;
}

`, "",{"version":3,"sources":["webpack://./src/Feis-Homepage/Header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;CAChB,gBAAgB;CAChB,iBAAiB;CACjB,6BAA6B;CAC7B,kBAAkB;CAClB,cAAc;CACd,oCAAoC;CACpC,WAAW;CACX,YAAY;AACb;AACA;EACE,oCAAoC;AACtC;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,YAAY;AACd;AACA;EACE,mCAAmC;EACnC,eAAe;EACf,YAAY;AACd,mBAAmB;AACnB;;AAEA;EACE,cAAc;AAChB,eAAe;AACf,gBAAgB;AAChB,YAAY;AACZ,mBAAmB;AACnB,cAAc;AACd;AACA;EACE,aAAa;EACb,eAAe;AACjB,gBAAgB;AAChB,cAAc;AACd","sourcesContent":[".login-ft-btn {\n  font-size: 16px;\n font-weight: 500;\n padding: 0px 11px;\n background-color: transparent;\n border-radius: 4px;\n color: #68AD3F;\n border: 2px solid #68AD3F !important;\n width: 80px;\n height: 42px;\n}\n.login-ft-btn::hover{\n  border: 2px solid #68AD3F !important;\n}\n.signup-btn {\n  font-size: 16px;\n  font-weight: 500;\n  padding: 9px 11px;\n  background-color: #68AD3F;\n  border-radius: 4px;\n  color: white;\n  border: none;\n  width: 80px;\n  height: 42px;\n}\n.create-event-col-1{\n  color: var(--neutral-text, #424242);\n  font-size: 14px;\n  display:flex;\njustify-content:end;\n}\n\n.create-event-col-2{\n  color: #FBB040;\nfont-size: 14px;\nfont-weight: 500;\ndisplay:flex;\njustify-content:end;\ncursor:pointer;\n}\n.create-event-col-3{\n  color:#68AD3F;\n  font-size: 14px;\nfont-weight: 500;\ncursor:pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
