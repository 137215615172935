import React from 'react'
import { useDispatch } from 'react-redux';
import { accessTillFees } from '../../helper/CreateDispatches';
import { serviceConsumer } from '../../network/ServiceConsumer';

const AddStripeAccount = ({ cards, seterrorconn, createOrEdit, setLoading }) => {
    const dispatch = useDispatch()

    // const connectStripe = async (e) => {
    //     e.preventDefault();
    //     try {
    //         setLoading(true)
    //         // create url is for paymentfeis page and connect is for stripe account page
    //         const createurl = `${process.env.REACT_APP_BASE_URL}/stripe/create-connect`;
    //         const connectUrl = ` ${process.env.REACT_APP_BASE_URL}/account/create-connect`
    //         const url = createOrEdit ? createurl : connectUrl
    //         const response = await serviceConsumer('POST', url);
    //         if (response.success === true) {
    //             window.location.href = response.result.url;
    //             createOrEdit && accessTillFees(dispatch)
    //         }
    //     } catch (error) {
    //         seterrorconn(error?.messages);
    //     }
    //     setLoading(false)
    // };

    const linkStripe = async (e) => {
        e.preventDefault()
        const type = createOrEdit ? "event" : "stripeaccount"
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/stripe/get-link-url?type=${type}`
            const response = await serviceConsumer("GET", url)
            if (response.success === true) {
                window.location.href = response?.result?.url;
                createOrEdit && accessTillFees(dispatch)
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    return (
        <div className="d-flex gap-2">
            {/* <button className={cards?.length < 3 ? "filled m-0" : "disabled m-0"} onClick={connectStripe} > Add Account </button>
            {createOrEdit && <button onClick={linkStripe} className="filled m-0">Link Account</button>} */}
            <button className={cards?.length < 3 ? "filled m-0" : "disabled m-0"} onClick={linkStripe} >Add/Link Account</button>
        </div>
    )
}

export default AddStripeAccount
