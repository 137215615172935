import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { RotatingLines } from "react-loader-spinner";

const InfiniteScrollComponent = (props) => {
    const { events, getAllEvents, hasMore } = props
    return (
        <InfiniteScroll
            dataLength={events.length} // This is important to track the length of the items
            next={getAllEvents} // Function to call when the user scrolls to the bottom
            hasMore={hasMore} // Flag to indicate if there are more items to load
            loader={<div className="d-flex justify-content-center">
                <RotatingLines
                    strokeColor="green"
                    strokeWidth="3"
                    animationDuration="0.75"
                    width="30"
                    visible={true}
                />
            </div>} // JSX element to show while loading more items
            // endMessage={<p>No more items to load.</p>} // JSX element to show when there are no more items
            scrollThreshold={1}//by deafult 0.8, set to 1 to reach bottom
        >
            {props.children}
        </InfiniteScroll>
    )
}

export default InfiniteScrollComponent