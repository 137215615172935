import React, { useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PaymentPage from "./PaymentPage";
import moment from "moment";
import ParentHeader from "../ParentHeader/ParentHeader";
import favIcon from "../../Assets/favicon.ico";
import { useLocation } from "react-router";
import PaymentGrade from "./PaymentGrade";
import { formatDate } from "../../helper/formatDate";

const StripePage = () => {
  const backToRegisterFeisEvent = () => {
    window.history.back();
  };

  const { state } = useLocation();
  const paymentMethod = state.paymentDetails;
  const eventDetails = state.eventDetails;

  useEffect(() => {
    document.title = `Feis.Link - Checkout`
  }, [])

  return (
    <div>
      <ParentHeader />
      <div>
        <div className="offset-md-2 col-md-8 col-10 offset-1 pt-2 ">
          <div
            className="d-flex align-items-center"
            onClick={backToRegisterFeisEvent}
            style={{ color: "#68AD3F", cursor: "pointer", width: " min-content", }}
          >
            <div><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
            <div style={{ fontWeight: "500", fontSize: "16px", marginTop: "1.5px", }}>Back</div>
          </div>
          <div className="d-flex pb-2 align-items-center">
            <div>
              <div></div>
              <img src={eventDetails?.eventImage || favIcon} className="eventimg" alt="logo" />
            </div>
            <div className="eventdetailss">
              <p className="title m-0">{eventDetails?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
              <p className="event-name m-0">{eventDetails?.eventName}</p>
              <p className="reg-date-open m-0">
                {eventDetails.eventDate ? moment(formatDate(eventDetails.eventDate)).format("MMMM Do, YYYY") : ""}{" "}
              </p>
            </div>
          </div>
        </div>

        <hr className="mt-0 " />
        {paymentMethod.showPaymentPage && paymentMethod.client && state?.from === "fiesReg" && !paymentMethod.errors && (
          <PaymentPage client={paymentMethod.client} from={state?.from} />
        )}
        {paymentMethod.showPaymentPage && paymentMethod.client && state?.from === "gradeReg" && !paymentMethod.errors && (
          <PaymentGrade client={paymentMethod.client} from={state?.from} />
        )}
        {paymentMethod.showPaymentPage && paymentMethod.client && state?.from === "editFies" && !paymentMethod.errors && (
          <PaymentPage client={paymentMethod.client} from={state?.from} />
        )}
        {paymentMethod.showPaymentPage && paymentMethod.client && state?.from === "editGrade" && !paymentMethod.errors && (
          <PaymentGrade client={paymentMethod.client} from={state?.from} />
        )}
      </div>
    </div>
  );
};

export default StripePage;
