import React, { useRef, useState } from 'react';
import { Formik, FieldArray, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import AddButton from "../../../../Components/AddButton/AddButton"
import ConfirmDeleteModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import "../../../../Components/Table.css"
import { CommentTd, DeleteIconTd, NumberInputTd } from './Component/CommonTableTds';

const TwoRoundTable = ({ competitors, adjudicator, event, compId, keyProp, results, getCompetitors, propLoading }) => {
  const navigate = useNavigate()
  const addNewRef = useRef(null);
  const saveRef = useRef(null);
  const [deletionIndex, setDeletionIndex] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")
  const [cardNum, setCardNum] = useState("")
  // let arr = [1]

  const initialValues = results?.length > 0 ? {
    tableData: results?.map((competitor) => {
      return {
        judge_id: adjudicator,
        dancer_id: competitor?.scoresArray[0]?.dancer_id,
        card_number: competitor?.card_number,
        round1: competitor?.scoresArray[0]?.score,
        round2: competitor?.scoresArray[1]?.score,
        comment1: competitor?.scoresArray[0]?.comments,
        comment2: competitor?.scoresArray[1]?.comments,
      };
    }),
  } : {
    tableData: competitors?.map((competitor) => {
      return {
        judge_id: adjudicator,
        dancer_id: "",
        card_number: "",
        round1: "",
        round2: "",
        comment1: "",
        comment2: "",
      };
    }),
  };

  const validationSchema = Yup.object().shape({
    tableData: Yup.array().of(
      Yup.object().shape({
        card_number: Yup.number()
          .required('required!')
          .test('is-valid-card-number', 'Invalid card number', function (value) {
            if (results.length <= 0) {
              return competitors.some(item => item.card_number == value);
            } else {
              return results[0]?.competitors?.some(item => item.card == value);
            }
          }),
        round1: Yup.number()
          .required('required!')
          .min(0, 'Score must be at least 0')
          .max(100, 'Score must be at most 100'),
        round2: Yup.number()
          .required('required!')
          .min(0, 'Score must be at least 0')
          .max(100, 'Score must be at most 100'),
        // comment1: Yup.string().required('required!'),
        // comment2: Yup.string().required('required!'),
      })
    ),
  });

  const handleSubmit = async (values) => {
    const changedTable = values.tableData.map((item, index) => {
      try {
        const foundCompetitor = results.length <= 0
          ? competitors.find(competitor => competitor.card_number == item.card_number)
          : results[0]?.competitors?.find(competitor => competitor.card == item.card_number);

        let dancerId;
        if (results?.length <= 0) dancerId = foundCompetitor ? foundCompetitor?.dancerData._id : null
        else dancerId = foundCompetitor ? foundCompetitor.dancerId : null
        return {
          ...item,
          sNo: index + 1,
          card_number: Number(item.card_number),
          dancer_id: dancerId,
        };
      } catch (err) {
      }

    });

    let payloadArr = []
    changedTable.forEach((item) => {
      payloadArr.push({
        card_number: Number(item?.card_number),
        dancer_id: item?.dancer_id,
        judge_id: item?.judge_id,
        score: item?.round1,
        comments: item?.comment1,
        round: 1,
        sNo: item.sNo
      }, {
        card_number: Number(item?.card_number),
        dancer_id: item?.dancer_id,
        judge_id: item?.judge_id,
        score: item?.round2,
        comments: item?.comment2,
        round: 2,
        sNo: item.sNo
      })
    })
    const cardNumbersSet = new Set();
    let duplicateFound = false;
    for (const item of changedTable) {
      if (cardNumbersSet.has(item.card_number)) {
        setErr("Dancers repeated")
        duplicateFound = true;
        break;
      } else {
        cardNumbersSet.add(item.card_number);
      }
    }
    if (!duplicateFound) {
      try {
        setLoading(true)
        const payload = { results: payloadArr }
        const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/${compId}`;
        const response = await serviceConsumer("POST", url, payload)
        navigate(`/complete-results/${event?.name}?id=${event?._id}&compId=${compId}`)
        setLoading(false)
      }
      catch (error) {
        setErr(error.messages)
        setLoading(false)
      }
    }
  }

  //functions to handle modal
  const openConfirmModal = (index, cardNum, dancerId) => {
    setDeletionIndex(index);
    setCardNum(cardNum)
    setShowConfirmModal(true);
  };

  const handleDeletionConfirmed = (remove) => {
    remove(deletionIndex);
    getCompetitors(compId);
    setShowConfirmModal(false);
    setErr("")
  };

  const handleDeletionCancelled = () => {
    setShowConfirmModal(false);
  };

  const addNewButtonHandler = () => {
    addNewRef.current && addNewRef.current.click && addNewRef.current.click()
  }
  const newRowsHandler = (values, setFieldValue, push) => {
    push({ card_number: '', round1: '', round2: "", comment1: "", comment2: "" });
    setFieldValue('tableData', [
      ...values.tableData,
      { judge_id: adjudicator, dancer_id: '', card_number: '', round1: "", round2: "", comment1: "", comment2: "" },
    ]);
  }
  return (
    <Formik
      key={keyProp}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form onChange={() => setErr("")} autoComplete="off">
          <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", }}>
            <table className="table my_table competitorTable mb-0">
              <FieldArray name="tableData">
                {({ push, remove }) => (
                  <tbody>
                    <tr className="header-row">
                      <td className="text-center w-10 p-0">S.No</td>
                      <td className="text-center w-10 p-0">###</td>
                      <td className="text-center w-10 p-0">Round 1</td>
                      <td className="text-center w-10 p-0 ">Round 2</td>
                      <td className='text-center w-27 p-0'>1 Round</td>
                      <td className='text-center w-27 p-0'>2 Round</td>
                      <td className='text-center p-0'>Action</td>
                    </tr>
                    {values.tableData.map((_, index) => (
                      <tr style={{ background: "#ffffff", height: "120px" }} key={index}>
                        <td className="w-10 grey-30">
                          <div className="input-code">
                            <Field placeholder="&nbsp;&nbsp;#" value={index + 1} readOnly />
                          </div>
                        </td>
                        <NumberInputTd className="w-10 grey-30" name={`tableData[${index}].card_number`} readOnly={true} />
                        <NumberInputTd className="w-10 grey-30" name={`tableData[${index}].round1`} />
                        <NumberInputTd className="w-10 grey-30" name={`tableData[${index}].round2`} />
                        <CommentTd className="w-27 grey-30" name={`tableData[${index}].comment1`} />
                        <CommentTd className="w-27 grey-30" name={`tableData[${index}].comment2`} />
                        <DeleteIconTd onClick={() => openConfirmModal(index, values.tableData[index].card_number, values.tableData[index].dancer_id)} />
                        {showConfirmModal && <ConfirmDeleteModal modalState={true} confirm={() => handleDeletionConfirmed(remove)} cancel={handleDeletionCancelled} adjudicator={adjudicator} compId={compId} card={cardNum} competitors={competitors} results={results} />}
                      </tr>
                    ))}
                    <div style={{ display: "none" }}>
                      <button type="button" ref={addNewRef} onClick={() => newRowsHandler(values, setFieldValue, push)}>New</button>
                      <button className="filled" type="submit" ref={saveRef}>Save</button>
                    </div>
                  </tbody>
                )}
              </FieldArray>
            </table>
            {err && <div className="error-class">{err}</div>}
          </div>
          {!propLoading && !loading && competitors?.length === 0 && localStorage.getItem("resultStatus") === "create" && <div className="my-3 text-center text-danger"> There are no competitors registered for this competition</div>}
          <div className="d-flex justify-content-between">
            <div><AddButton className="plusIconbtn" onClick={addNewButtonHandler}>New Row</AddButton></div>
            <div className="d-flex align-items-center"> <button className={competitors?.length > 0 || results?.length > 0 ? "filled mb-5" : "disabled mb-5"} type="submit" >Save</button></div>
          </div>
        </Form>
      )}
    </Formik>

  )
}

export default TwoRoundTable