import React from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import feisLogo from '../Assets/feis-logo.svg';

const Header = () => {
  const navigate = useNavigate();

  //navigate to login page
  const goToLoginPage = () => {
    navigate('/login')
  };

  //navigate to signup page
  const goToSignUpPage = () => {
    navigate('/signup');
  };

  //navigate to organizer login
  const goToOrganizerSignup = () => {
    navigate('/signup-org')
  }

  const goToOrganizerLogin = () => {
    navigate('/login-org')
  }

  //img click handler
  const refreshHandler = () => {
    window.location.reload()
  }

  return (
    <Container>
      <div className="d-flex flex-column flex-md-row align-items-md-center px-4 px-md-0 mt-4 my-md-5">
        <div className="d-flex justify-content-between flex-grow-1" >
          <div>
            <img className="feis-logo" src={feisLogo} onClick={refreshHandler} alt="logo" />
          </div>
          <div className="d-flex gap-3 gap-md-4">
            <div><button className="outlined" onClick={goToLoginPage}>Log in</button></div>
            <div><button className="filled" onClick={goToSignUpPage}>Sign up</button></div>
            <span class="border-right me-4 d-none d-md-inline" style={{ borderRight: "1px solid #E4E4E4" }}></span>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-md-0" >
          <div className="create-event-col-1">Do you want to create an event?</div>
          <div className="d-flex justify-content-end">
            <div className=" create-event-col-2" onClick={goToOrganizerSignup}>Sign Up as An Organizer</div>
            <span class="border-right mx-1 d-inline" style={{ borderRight: "1px solid #303030", marginTop: "3px", height: "15px" }}></span>
            <div className=" create-event-col-3" onClick={goToOrganizerLogin}>Log In</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
