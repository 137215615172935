import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import feisLogo from "../../Assets/feis-logo.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import "./style.css";
import axios from "axios";
import SuccessContent from "../../Components/SuccessContent";

const Congratulations = ({ edit }) => {
  const { state } = useLocation()
  const isEditPage = state?.isEdit
  const eventName = state?.name
  const [loader, setloader] = useState()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [params, SetParams] = useSearchParams();
  const [event, setevent] = useState([])
  const [counter, setCounter] = useState(0);
  const client_secret = params.get("payment_intent_client_secret");
  const paymentId = params.get("payment_intent");
  const [pay, setPay] = useState([])

  const payload = {
    paymentId: paymentId,
  }
  const paymentPage = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/stripe/confirmpayment`;
    const config = {
      headers: {
        Authorization: "Basic " + window.btoa('feisLink' + ":" + 'feisLink@123'),
        accesstoken: localStorage.getItem('accessToken')
      },
    }
    try {
      setLoading(true)
      const res = await axios.post(url, payload, config);

      // if (res.data.response.status == "succeeded") {
      //   const payload = {
      //     paymentId: paymentId,
      //   }
      //   const config = {
      //     headers: {
      //       Authorization: "Basic " + window.btoa('feisLink' + ":" + 'feisLink@123'),
      //       accesstoken: localStorage.getItem('accessToken')
      //     },

      //   }
      //   const urls = edit ? `${process.env.REACT_APP_BASE_URL}/registration/edit` : `${process.env.REACT_APP_BASE_URL}/registration`
      //   const response = await axios.post(urls, payload, config)
      //   edit ? setevent(response.data.eventName) : setevent(response.data.result.eventName)
      // }
      setPay(res.data.response.status)
      setLoading(false)
    } catch (err) {
      setevent(eventName)
      setLoading(false)
    }

  }
  setTimeout(() => { setCounter(10) }, 10000);

  useEffect(() => {
    if (paymentId) paymentPage()
    else setevent(eventName)
  }, [paymentId]);

  if (counter === 10) {
    navigate("/parent");
  }

  useEffect(() => {
    document.title = `Feis.Link - Success`
  })

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      {loading && <Loader />}
      <div>
        <img
          src={feisLogo}
          alt=""
          className="m-4"
          style={{ cursor: "pointer", position: "absolute", top: "0", left: "0" }}
          onClick={() => navigate("/")}
        />
      </div>
      <br />
      <Container className="d-flex justify-content-center">
        <SuccessContent eventName={event} edit={edit} />
      </Container>
    </div>
  );
};

export default Congratulations;
