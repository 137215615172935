// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  height: 374px;
  border: none;
}
.modal-header {
  border-bottom: none !important;
}
.modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: 200px;
}
.modal-footer {
  justify-content: center !important;
  border: none !important;
  padding: 0px 10px 0px 10px;
  height: 110px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/LogoutModal/LogoutModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;AACA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,0BAA0B;EAC1B,aAAa;AACf","sourcesContent":[".modal-content {\n  height: 374px;\n  border: none;\n}\n.modal-header {\n  border-bottom: none !important;\n}\n.modal-body {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  padding: 0px;\n  height: 200px;\n}\n.modal-footer {\n  justify-content: center !important;\n  border: none !important;\n  padding: 0px 10px 0px 10px;\n  height: 110px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
