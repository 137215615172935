import React, { useState, useEffect } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import { Form, Formik } from "formik";

import "./Style.css";
import FormikControl from "../../Components/formik/FormikControl";
import Loader from "../../Components/Loader/Loader";
import { SIGNIN } from "../../redux/actionTypes/authActionType";
import { regionData } from "../../helper/DataArrays";
import { countryValidation, fnameValidation, lnameValidation } from "../../helper/Validations";
import { serviceConsumer } from "../../network/ServiceConsumer";
import Button from "../../Components/Button/Button"

const Profile = () => {
  const [userData, setUserdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState("");
  const [showError, setShowError] = useState("");
  const dispatch = useDispatch();

  //get useDetails to update initial values
  const userDetails = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/user`;
    setLoading(true);
    try {
      const response = await serviceConsumer("GET", url)
      setUserdata(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    userDetails();
    document.title = "Feis.Link - My account"
  }, []);

  //formik initial values
  const profileInitialValues = {
    fname: userData.fname,
    lname: userData.lname,
    email: userData.email,
    country: userData.country,
  };

  //formik validation schema
  const profileValidationSchema = Yup.object().shape({
    fname: fnameValidation,
    lname: lnameValidation,
    country: countryValidation,
  });

  //formik submit handler
  const profileUpdateHandler = async (values) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user`;
    try {
      const response = await serviceConsumer("PUT", url, values)
      localStorage.setItem("userFirstName", values.fname)
      if (response.success) {
        dispatch({
          type: SIGNIN,
          payload: values,
        });
        localStorage.setItem("userFirstName", values.fname)
        setShowSuccess("User details updated successfully");
        setTimeout(() => { userDetails(); setShowSuccess("") }, 3000);
      }
      userDetails()
    } catch (error) {
      setShowError(error.messages);
      setTimeout(() => setShowError("", 1000));
    }
  };

  //compare initial and formik values for button emnable/disable
  let isEqual = (formValues, initialValues) => {
    let equalState = Object.keys(formValues).every(k1 =>
      Object.keys(initialValues).some(k2 =>
        formValues[k1] == initialValues[k2]
      )
    )
    return equalState
  }

  return (
    <>
      {loading && <Loader />}
      <TabPanel value="1">
        <Formik
          initialValues={profileInitialValues}
          enableReinitialize={true}
          validationSchema={profileValidationSchema}
          onSubmit={profileUpdateHandler}
        >
          {(formik) => (
            <Form className="account-form" autoComplete="off">
              <h4 className="title-signin grey-42" >Profile</h4>
              <p className="grey-42 mb-2">Username and others.</p>

              <hr className="mt-0" />
              {showSuccess && (<Alert sx={{ color: "#68AD3F", margin: "10px 0" }} icon={false} severity="success">{showSuccess}</Alert>)}
              {showError && (<Alert style={{ color: "error", margin: "10px 0" }} icon={false} severity="error"> {showError}</Alert>)}
              <FormikControl
                control="input"
                type="text"
                label="First Name:"
                name="fname"
                placeHolder="First Name"
                maxLength={30}
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name:"
                placeHolder="Last Name"
                name="lname"
                maxLength={30}
              />
              <FormikControl
                className="disabled-email"
                control="input"
                type="email"
                label="Email Address:"
                name="email"
                value={userData.email}
                disabled
              />
              <FormikControl
                className="formikSelect"
                control="select"
                label="Region:"
                showAs="Region"
                name="country"
                options={regionData}
              />
              <br />
              <Button className={(isEqual(formik.values, profileInitialValues) || !formik.isValid) ? "disabled mt-0 mb-5" : "filled mt-0 mb-5"} type="submit">
                Update Changes
              </Button>
            </Form>
          )}
        </Formik>
      </TabPanel>
    </>
  );
};

export default Profile;
